import { Trans } from '@lingui/macro'
import { VoteOption } from 'state/governance/hooks'

import {
  ApproveTransactionInfo,
  CancelProposalTransactionInfo,
  ClaimTransactionInfo,
  DelegateTransactionInfo,
  DepositTransactionInfo,
  ExecuteProposalTransactionInfo,
  QueueProposalTransactionInfo,
  SubmitProposalTransactionInfo,
  TransactionInfo,
  TransactionType,
  VoteTransactionInfo,
  WithdrawLiquidityStakingTransactionInfo,
} from '../../state/transactions/types'

function ClaimSummary({ info: { recipient, uniAmountRaw } }: { info: ClaimTransactionInfo }) {
  return <Trans>Claim reward for {recipient}</Trans>
}

function SubmitProposalTransactionSummary(_: { info: SubmitProposalTransactionInfo }) {
  return <Trans>Submit new proposal</Trans>
}

function CancelProposalTransactionSummary({ info }: { info: CancelProposalTransactionInfo }) {
  return <Trans>Cancel proposal {info?.proposalId}</Trans>
}

function QueueProposalTransactionSummary({ info }: { info: QueueProposalTransactionInfo }) {
  return <Trans>Queue proposal {info?.proposalId}</Trans>
}

function ExecuteProposalTransactionSummary({ info }: { info: ExecuteProposalTransactionInfo }) {
  return <Trans>Execute proposal {info?.proposalId}</Trans>
}

function ApprovalSummary({ info }: { info: ApproveTransactionInfo }) {
  // const token = useToken(info.tokenAddress)

  return <Trans>Approve {info?.tokenAddress}</Trans>
}

function VoteSummary({ info }: { info: VoteTransactionInfo }) {
  const proposalKey = `${info.governorAddress}/${info.proposalId}`
  if (info.reason && info.reason.trim().length > 0) {
    switch (info.decision) {
      case VoteOption.For:
        return <Trans>Vote for proposal {proposalKey}</Trans>
      case VoteOption.Abstain:
        return <Trans>Vote to abstain on proposal {proposalKey}</Trans>
      case VoteOption.Against:
        return <Trans>Vote against proposal {proposalKey}</Trans>
    }
  } else {
    switch (info.decision) {
      case VoteOption.For:
        return (
          <Trans>
            Vote for proposal {proposalKey} with reason &quot;{info.reason}&quot;
          </Trans>
        )
      case VoteOption.Abstain:
        return (
          <Trans>
            Vote to abstain on proposal {proposalKey} with reason &quot;{info.reason}&quot;
          </Trans>
        )
      case VoteOption.Against:
        return (
          <Trans>
            Vote against proposal {proposalKey} with reason &quot;{info.reason}&quot;
          </Trans>
        )
    }
  }
}

function DelegateSummary({ info: { delegatee } }: { info: DelegateTransactionInfo }) {
  return <Trans>Delegate voting power to {delegatee}</Trans>
}

function DepositLiquidityStakingSummary(_: { info: DepositTransactionInfo }) {
  // not worth rendering the tokens since you can should no longer deposit liquidity in the staking contracts
  // todo: deprecate and delete the code paths that allow this, show user more information
  return <Trans>Deposit liquidity</Trans>
}

function WithdrawLiquidityStakingSummary(_: { info: WithdrawLiquidityStakingTransactionInfo }) {
  return <Trans>Withdraw deposited liquidity</Trans>
}

export function TransactionSummary({ info }: { info: TransactionInfo }) {
  switch (info.type) {
    case TransactionType.CLAIM:
      return <ClaimSummary info={info} />

    case TransactionType.DEPOSIT:
      return <DepositLiquidityStakingSummary info={info} />

    case TransactionType.WITHDRAW:
      return <WithdrawLiquidityStakingSummary info={info} />

    case TransactionType.APPROVAL:
      return <ApprovalSummary info={info} />

    case TransactionType.VOTE:
      return <VoteSummary info={info} />

    case TransactionType.DELEGATE:
      return <DelegateSummary info={info} />

    case TransactionType.SUBMIT_PROPOSAL:
      return <SubmitProposalTransactionSummary info={info} />

    case TransactionType.CANCEL_PROPOSAL:
      return <CancelProposalTransactionSummary info={info} />

    case TransactionType.QUEUE_PROPOSAL:
      return <QueueProposalTransactionSummary info={info} />

    case TransactionType.EXECUTE_PROPOSAL:
      return <ExecuteProposalTransactionSummary info={info} />
  }
}
