import { BigNumber } from '@ethersproject/bignumber'
import { formatEther } from '@ethersproject/units'
import { Trans } from '@lingui/macro'
import { ButtonConfirmed } from 'components/Button'
import { AutoColumn } from 'components/Column'
import ApproveModal from 'components/Earn/ApproveModal'
import ClaimRewardModal from 'components/Earn/ClaimRewardModal'
import StakingModal from 'components/Earn/StakingModal'
import WithdrawModal from 'components/Earn/WithdrawModal'
import Header from 'components/Header'
import NumericalInput from 'components/NumericalInput'
import { RowBetween, RowCenter } from 'components/Row'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCultContract, useDCultContract } from 'hooks/useContract'
import {
  ArrowRightBorderBottom,
  ArrowRightBorderHorContent,
  ArrowRightBorderLeft,
  ArrowRightBorderLeftBottom,
  ArrowRightBorderLeftLine,
  ArrowRightBorderLeftUp,
  ArrowRightBorderRight,
  ArrowRightBorderRightBottom,
  ArrowRightBorderRightUp,
  ArrowRightBorderTop,
  ArrowRightContainer,
  ArrowRightContent,
  ArrowRightWrapper,
} from 'pages/Home/styles'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  useModalOpen,
  useToggleApprovalModal,
  useToggleDepositModal,
  useToggleSelfClaimModal,
  useToggleWithdrawModal,
} from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { useIsTransactionConfirmed } from 'state/transactions/hooks'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const DataCard = styled(AutoColumn)<{ disabled?: boolean }>`
  width: 100%;
  position: relative;
  overflow: hidden;
  border: 2px solid #fff;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    border: none;
    display: block;
  `};
`

const CardSection = styled(AutoColumn)<{ disabled?: boolean }>`
  padding: 1rem;
  z-index: 1;
  opacity: ${({ disabled }) => disabled && '0.4'};
`
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 9.85vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-top: 5.92vh;
  `};
`

const VoteCard = styled(DataCard)`
  width: 53vw;
  height: 82.7778vh;
  background-image: url('../images/StakeBack.png');
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    background: none;
    width: 100%;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
  `};
`
const Container = styled.div<{ hideInput: boolean }>`
  border: 2px solid #fff;
  display: flex;
  flex-direction: row;
  width: 31.67vw;
  height: 7.5vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: 5.21vh;
  `};
`
const Input = styled(NumericalInput)`
  width: 100%;
  height: 100%;
  padding-left: 30px;
  padding-right: 30px;
  background-color: transparent;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-left: 10px;
    padding-right: 10px;
  `};
`

const ConfirmButton = styled(ButtonConfirmed)`
  margin-top: 3.7vh;
  border-radius: 0;
  width: 13.89vw;
  background-color: #fff;
  font-size: 2.04vh;
  color: #222;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 25.64vw;
    margin-top: 2.01vh;
    height: 4.03vh;
    font-size: 1.42vh;
  `};
`

const Text = styled(ThemedText.White)`
  font-size: 2.13vh;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.66vh;
  `};
`

const GoBackButton = styled(ArrowRightContainer)`
  left: 87.5vw;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    left: 82vw;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    left: 82vw;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    left: 30vw;
  `};
`
const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
`

const MaxButton = styled.div`
  border: 1px solid #fff;
  border-radius: 4px;
  text-aligin: center;
  display: flex;
  height: fit-content;
  align-items: center;
  margin: auto 8px;
  padding: 8px 12px;
  &:hover {
    background-color: #ccc;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 4px 12px;
  `};
`

export default function Landing() {
  const [curCultBalance, setCurCultBalance] = useState<string>('0.0')
  const [curDCultBalance, setCurDCultBalance] = useState<string>('0.0')
  const { account } = useActiveWeb3React()
  const contract = useCultContract(true)
  const dContract = useDCultContract(true)
  const [stakeValue, setStakeValue] = useState<string>('')
  const [withdrawValue, setWithdrawValue] = useState<string>('')
  const [pendingCult, setPendingCult] = useState<string>('')
  const [allowance, setAllowance] = useState<BigNumber>(BigNumber.from(0))
  const history = useHistory()

  const [depositHash, setDepositHash] = useState<string>('')
  const showDepositModal = useModalOpen(ApplicationModal.DEPOSIT)
  const toggleDepositModal = useToggleDepositModal()

  const [withdrawHash, setWithdrawHash] = useState<string>('')
  const showWithdrawModal = useModalOpen(ApplicationModal.WITHDRAW)
  const toggleWithdrawModal = useToggleWithdrawModal()

  const [approvalHash, setApprovalHash] = useState<string>('')
  const showApprovalModal = useModalOpen(ApplicationModal.APPROVAL)
  const toggleApprovalModal = useToggleApprovalModal()

  const [claimHash, setClaimHash] = useState<string>('')
  const showClaimModal = useModalOpen(ApplicationModal.SELF_CLAIM)
  const toggleClaimModal = useToggleSelfClaimModal()

  const refreshData = useCallback(() => {
    if (account) {
      contract
        ?.balanceOf(account)
        .then((balance: BigNumber) => {
          const cultBalance = formatEther(balance)
          setCurCultBalance(parseInt(cultBalance).toFixed(0))
        })
        .catch((err: any) => {
          console.log(err)
        })
      dContract
        ?.balanceOf(account)
        .then((balance: BigNumber) => {
          const dCultBalance = formatEther(balance)
          setCurDCultBalance(parseInt(dCultBalance).toFixed(0))
        })
        .catch((err: any) => {
          console.log(err)
        })

      contract
        ?.allowance(account, dContract?.address ? dContract.address : '')
        .then(async (response) => {
          console.log('allowance', response)
          setAllowance(response)
        })
        .catch((err: any) => {
          console.log(err)
        })
      dContract
        ?.pendingINCU(0, account)
        .then((pending) => {
          console.log('pendingCULT', pending)
          const pendingTemp = formatEther(pending)
          setPendingCult(parseInt(pendingTemp).toFixed(0))
        })
        .catch((error: any) => {
          console.log('pendingCULT', error)
        })
      dContract
        ?.numCheckpoints(account)
        .then((votes) => {
          console.log('numCheckpoints', votes)
        })
        .catch((error: any) => {
          console.log('numCheckpoints', error)
        })
      dContract
        ?.poolInfo(0)
        .then((length) => {
          console.log('length', length)
        })
        .catch((error: any) => {
          console.log('poolLength', error)
        })
    }
  }, [account, contract, dContract])

  useEffect(() => {
    refreshData()
  }, [refreshData])

  const depositConfirmed = useIsTransactionConfirmed(depositHash)
  const withdrawConfirmed = useIsTransactionConfirmed(withdrawHash)
  const approvalConfirmed = useIsTransactionConfirmed(approvalHash)
  const claimConfirmed = useIsTransactionConfirmed(claimHash)

  useEffect(() => {
    if (depositConfirmed || withdrawConfirmed || approvalConfirmed || claimConfirmed) {
      setDepositHash('')
      setWithdrawHash('')
      setApprovalHash('')
      setClaimHash('')
      refreshData()
    }
  }, [depositConfirmed, withdrawConfirmed, refreshData, approvalConfirmed, claimConfirmed])

  const onDepositDismiss = useCallback(
    (hash: string) => {
      toggleDepositModal()
      setDepositHash(hash)
    },
    [toggleDepositModal]
  )

  const onWithdrawDismiss = useCallback(
    (hash: string) => {
      toggleWithdrawModal()
      setWithdrawHash(hash)
    },
    [toggleWithdrawModal]
  )

  const onApprovalDismiss = useCallback(
    (hash: string) => {
      toggleApprovalModal()
      setApprovalHash(hash)
    },
    [toggleApprovalModal]
  )

  const onClaimDismiss = useCallback(
    (hash: string) => {
      toggleClaimModal()
      setClaimHash(hash)
    },
    [toggleClaimModal]
  )
  return (
    <>
      <HeaderWrapper>
        <Header></Header>
      </HeaderWrapper>
      <PageWrapper>
        <StakingModal isOpen={showDepositModal} amount={stakeValue} onDismiss={onDepositDismiss}></StakingModal>
        <WithdrawModal isOpen={showWithdrawModal} amount={withdrawValue} onDismiss={onWithdrawDismiss}></WithdrawModal>
        <ApproveModal isOpen={showApprovalModal} onDismiss={onApprovalDismiss}></ApproveModal>
        <ClaimRewardModal isOpen={showClaimModal} onDismiss={onClaimDismiss}></ClaimRewardModal>
        <Content>
          <VoteCard>
            <CardSection>
              <AutoColumn gap={'md'}>
                <RowBetween>
                  <Text>
                    <Trans>INCU Balance</Trans>
                  </Text>
                  <Text>
                    <>{curCultBalance}</>
                  </Text>
                </RowBetween>
                <Container hideInput={false}>
                  <Input
                    className="token-amount-input"
                    value={stakeValue}
                    onUserInput={(value) => {
                      if (value.length === 0) {
                        setStakeValue('')
                      } else {
                        setStakeValue(value)
                      }
                    }}
                    disabled={parseInt(curCultBalance) <= 0}
                  />
                  <MaxButton
                    onClick={() => {
                      setStakeValue(curCultBalance)
                    }}
                  >
                    <Trans>Max</Trans>
                  </MaxButton>
                </Container>

                <RowCenter>
                  <ConfirmButton
                    mr="0.5rem"
                    onClick={async () => {
                      if (account) {
                        if (allowance.gt(0)) {
                          toggleDepositModal()
                        } else {
                          toggleApprovalModal()
                        }
                      }
                    }}
                    confirmed={true}
                    disabled={
                      allowance.gt(0) &&
                      (parseInt(curCultBalance) <= 0 ||
                        stakeValue === '' ||
                        (!curCultBalance &&
                          !stakeValue &&
                          BigNumber.from(stakeValue).gt(BigNumber.from(curCultBalance))))
                    }
                  >
                    {allowance.gt(0) ? <Trans>Stake</Trans> : <Trans>Approve</Trans>}
                  </ConfirmButton>
                </RowCenter>
              </AutoColumn>
            </CardSection>
            <CardSection>
              <AutoColumn gap={'md'}>
                <RowBetween>
                  <Text>
                    <Trans>deCU Balance</Trans>
                  </Text>
                  <Text>
                    <>{curDCultBalance}</>
                  </Text>
                </RowBetween>
                <Container hideInput={false}>
                  <Input
                    className="token-amount-input"
                    value={withdrawValue}
                    onUserInput={(value) => {
                      if (value.length === 0) {
                        setWithdrawValue('')
                      } else {
                        setWithdrawValue(value)
                      }
                    }}
                    disabled={parseInt(curDCultBalance) <= 0}
                  />
                  <MaxButton
                    onClick={() => {
                      setWithdrawValue(curDCultBalance)
                    }}
                  >
                    <Trans>Max</Trans>
                  </MaxButton>
                </Container>

                <RowCenter>
                  <ConfirmButton
                    mr="0.5rem"
                    onClick={async () => {
                      if (account) {
                        toggleWithdrawModal()
                      }
                    }}
                    confirmed={true}
                    disabled={
                      parseInt(curDCultBalance) <= 0 ||
                      withdrawValue === '' ||
                      (!curDCultBalance &&
                        !withdrawValue &&
                        BigNumber.from(withdrawValue).gte(BigNumber.from(curDCultBalance)))
                    }
                  >
                    <Trans>Withdraw</Trans>
                  </ConfirmButton>
                </RowCenter>
              </AutoColumn>
            </CardSection>
            <CardSection>
              <AutoColumn gap={'md'}>
                <RowBetween>
                  <Text>
                    <Trans>Reward:</Trans>
                  </Text>
                  <Text>{pendingCult}</Text>
                </RowBetween>

                <RowCenter>
                  <ConfirmButton
                    mr="0.5rem"
                    onClick={async () => {
                      if (account) {
                        toggleClaimModal()
                      }
                    }}
                    confirmed={true}
                    disabled={parseFloat(pendingCult) <= 0}
                  >
                    <Trans>Claim</Trans>
                  </ConfirmButton>
                </RowCenter>
              </AutoColumn>
            </CardSection>
          </VoteCard>
        </Content>
        <GoBackButton
          onClick={() => {
            history.goBack()
          }}
        >
          <ArrowRightWrapper>
            <ArrowRightContent></ArrowRightContent>
          </ArrowRightWrapper>
          <ArrowRightBorderLeft>
            <ArrowRightBorderLeftUp>
              <ArrowRightBorderLeftLine></ArrowRightBorderLeftLine>
            </ArrowRightBorderLeftUp>
            <ArrowRightBorderLeftBottom>
              <ArrowRightBorderLeftLine></ArrowRightBorderLeftLine>
            </ArrowRightBorderLeftBottom>
          </ArrowRightBorderLeft>
          <ArrowRightBorderTop>
            <ArrowRightBorderHorContent></ArrowRightBorderHorContent>
          </ArrowRightBorderTop>
          <ArrowRightBorderBottom>
            <ArrowRightBorderHorContent></ArrowRightBorderHorContent>
          </ArrowRightBorderBottom>
          <ArrowRightBorderRight>
            <ArrowRightBorderRightUp></ArrowRightBorderRightUp>
            <ArrowRightBorderRightBottom></ArrowRightBorderRightBottom>
          </ArrowRightBorderRight>
        </GoBackButton>
      </PageWrapper>
    </>
  )
}
