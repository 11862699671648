import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }

export const TIMELOCK_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x5BA1443a42958561C55894dc570f7315BEb0c0Ff',
}

export const TREASURY_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x09eBaf3A26FC16c53527Ace1F3f9A3A0870138b6',
}

export const INCU_TOKEN_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xebc8EB72451310Cf8A1AA40B61b7673c9CeCb547',
}

export const DECU_TOKEN_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xEb85eBCa808417841827B6eF878Db499808Ec2A7',
}

export const GOVERNANCE_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x60b399f9f0B3dBC15D7a1B35d6eba4737Ae4D8C9',
}
