import 'react-alice-carousel/lib/alice-carousel.css'

import { Trans } from '@lingui/macro'
import { useSpring } from '@react-spring/web'
import { useCallback, useEffect, useRef, useState } from 'react'
import AliceCarousel from 'react-alice-carousel'
import { useHistory } from 'react-router-dom'
import { MEDIA_WIDTHS } from 'theme'

import { DecuPanel } from './DecuPanel'
import { Menu } from './Menu'
import * as S from './styles'

export default function Home() {
  const [inProp, setInProp] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [showDecuPanel, setShowDecuPanel] = useState(false)
  const [horLine1, setHorLine1] = useState(false)
  const [parUpLine, setParUpLine] = useState(false)
  const [parHorLine, setParHorLine] = useState(false)
  const [parBottomLine, setParBottomLine] = useState(false)
  const [horLine2, setHorLine2] = useState(false)
  const [downUpLine, setDownUpLine] = useState(false)
  const [downHorLine, setDownHorLine] = useState(false)
  const [downBottomLine, setDownBottomLine] = useState(false)
  const [verLine, setVerLine] = useState(false)
  const [arrowHor, setArrowHor] = useState(false)
  const [arrowVer, setArrowVer] = useState(false)
  const [arrowHorRight, setArrowHorRight] = useState(false)
  const [sliderShow, setSliserShow] = useState(false)
  const [mQuery] = useState<boolean>(window.innerWidth > MEDIA_WIDTHS.upToExtraSmall ? true : false)
  const slider = useRef<AliceCarousel>(null)
  const history = useHistory()

  const { downArrowX } = useSpring({
    downArrowX: showDecuPanel ? '180deg' : '0deg',
    config: { duration: 200 },
  })

  useEffect(() => {
    setInProp(true)
  }, [])
  const items = [
    <S.SlideContainer key={0}>
      <S.Invision></S.Invision>
      <S.SliderDesPanel>
        <S.SliderDesContent>
          <Trans>
            The traditional VC is woefully inefficient that shrouded in mystery and secrecy. INCU.DAO is the future
            venture fundraising and investing model allows everyone to participate in ecosystem governance and invest in
            promising projects with transparency, efficiency and trust.
          </Trans>
        </S.SliderDesContent>
      </S.SliderDesPanel>
      <S.SliderTabPanel>
        <S.SliderTabDot></S.SliderTabDot>
        <S.SliderTabLine></S.SliderTabLine>
        <S.SliderTabContent>
          <S.SliderTabText>
            <Trans>Vision</Trans>
          </S.SliderTabText>
        </S.SliderTabContent>
      </S.SliderTabPanel>
    </S.SlideContainer>,
    <S.SlideContainer key={1}>
      <S.Incu></S.Incu>
      <S.SliderDesPanel>
        <S.SliderDesContent>
          <Trans>
            $INCU is the tradable and liquid token of INCU.DAO. Transacting INCU will contribute to the protocol by
            filling the DAO treasury to fund investments into decentralized projects. This is achieved due to a 0.4%
            collection on all INCU transactions that goes straight to the DAO.
          </Trans>
        </S.SliderDesContent>
      </S.SliderDesPanel>
      <S.SliderTabPanel>
        <S.SliderTabDot></S.SliderTabDot>
        <S.SliderTabLine></S.SliderTabLine>
        <S.SliderTabContent>
          <S.SliderTabText>
            <Trans>INCU TOKEN</Trans>
          </S.SliderTabText>
        </S.SliderTabContent>
      </S.SliderTabPanel>
    </S.SlideContainer>,
    <S.SlideContainer key={2}>
      <S.Decu></S.Decu>
      <S.SliderDesPanel>
        <S.SliderDesContent>
          <Trans>
            $deCU is the governace token of INCU.DAO. When you stake INCU, you are given deCU, this can be swapped back
            at any time into the amount of INCU. <br />
            $deCU allows you to vote on proposal and receive rewards from investment.
          </Trans>
        </S.SliderDesContent>
      </S.SliderDesPanel>
      <S.SliderTabPanel>
        <S.SliderTabDot></S.SliderTabDot>
        <S.SliderTabLine></S.SliderTabLine>
        <S.SliderTabContent>
          <S.SliderTabText>
            <Trans>deCU TOKEN</Trans>
          </S.SliderTabText>
        </S.SliderTabContent>
      </S.SliderTabPanel>
    </S.SlideContainer>,
    <S.SlideContainer key={3}>
      <S.Guardian></S.Guardian>
      <S.SliderDesPanel>
        <S.SliderDesContent>
          <Trans>
            The top 50 $deCU stakers are The Guardians, who can submit proposals but cannot vote. The votes can only be
            made by all $deCU stakers who are not Guardians.
          </Trans>
        </S.SliderDesContent>
      </S.SliderDesPanel>
      <S.SliderTabPanel>
        <S.SliderTabDot></S.SliderTabDot>
        <S.SliderTabLine></S.SliderTabLine>
        <S.SliderTabContent>
          <S.SliderTabText>
            <Trans>Guardian</Trans>
          </S.SliderTabText>
        </S.SliderTabContent>
      </S.SliderTabPanel>
    </S.SlideContainer>,
    <S.SlideContainer key={4}>
      <S.Proposal></S.Proposal>
      <S.SliderDesPanel>
        <S.SliderDesContent>
          <Trans>
            $Only the top 50 Guardians can submit a proposal. (Desktop only). The proposed projects must align with
            INCU.DAO&#x27;s vision and for the best interest of all INCU stakers. Then it is upto all deCU stakers to
            vote.
          </Trans>
        </S.SliderDesContent>
      </S.SliderDesPanel>
      <S.SliderTabPanel>
        <S.SliderTabDot></S.SliderTabDot>
        <S.SliderTabLine></S.SliderTabLine>
        <S.SliderTabContent>
          <S.SliderTabText>
            <Trans>Proposal</Trans>
          </S.SliderTabText>
        </S.SliderTabContent>
      </S.SliderTabPanel>
    </S.SlideContainer>,
  ]

  const { decuPanelHeight } = useSpring({
    decuPanelHeight: showDecuPanel ? '100%' : '0%',
    config: { duration: 200 },
  })

  const handlePreClick = useCallback(() => {
    if (showDecuPanel) {
      history.push('/stake')
      return
    }
    if (slider) slider?.current?.slidePrev(null)
  }, [history, showDecuPanel])

  const handleNextClick = useCallback(() => {
    if (showDecuPanel) {
      history.push('/vote')
      return
    }
    if (slider) slider?.current?.slideNext(null)
  }, [history, showDecuPanel])

  // const { stakePanelWidth } = useSpring({
  //   stakePanelWidth: showStakePanel ? '100%' : '0%',
  //   config: { duration: 200 },
  // })

  return (
    <>
      {sliderShow && (
        <>
          <S.SliderPanel>
            <AliceCarousel
              ref={slider}
              disableButtonsControls
              infinite
              disableDotsControls
              mouseTracking
              items={items}
            />
            <DecuPanel decuPanelHeight={decuPanelHeight}></DecuPanel>
          </S.SliderPanel>
          {/* <StakePanel stakePanelWidth={stakePanelWidth}></StakePanel> */}
        </>
      )}
      <S.HorLine1>
        {inProp && (
          <S.HorLineContent
            onAnimationEnd={() => {
              setShowMenu(true)
            }}
          ></S.HorLineContent>
        )}
      </S.HorLine1>
      {showMenu && (
        <Menu
          startAnimate={showMenu}
          onMenuAnimationEnd={() => {
            setHorLine1(true)
          }}
        ></Menu>
      )}
      <S.HorLine6>
        {horLine1 && (
          <S.HorLineContent
            onAnimationEnd={() => {
              setParUpLine(true)
            }}
          ></S.HorLineContent>
        )}
      </S.HorLine6>
      <S.ParticlesBox>
        {parUpLine && (
          <S.ParticlesUp
            onAnimationEnd={() => {
              setParHorLine(true)
            }}
          ></S.ParticlesUp>
        )}
        {parHorLine && <S.ParticlesLeft onAnimationEnd={() => setParBottomLine(true)}></S.ParticlesLeft>}
        {parHorLine && <S.ParticlesRight></S.ParticlesRight>}
        <S.ParticlesBottom>
          {parBottomLine && (
            <S.ParticlesBottomLeft
              onAnimationEnd={() => {
                setHorLine2(true)
              }}
            ></S.ParticlesBottomLeft>
          )}
          {parBottomLine && <S.ParticlesBottomRight></S.ParticlesBottomRight>}
        </S.ParticlesBottom>
        {sliderShow && (
          <S.ParticlesContent>
            <Trans>INCU.DAO is a decentralised VC aiming to fund web3 gaming projects.</Trans>
          </S.ParticlesContent>
        )}
      </S.ParticlesBox>
      <S.HorLine7>
        {horLine2 && (
          <S.HorLineContent
            onAnimationEnd={() => {
              setDownUpLine(true)
            }}
          ></S.HorLineContent>
        )}
      </S.HorLine7>
      <S.DownArrowPanel onClick={() => setShowDecuPanel(!showDecuPanel)}>
        <S.DownArrowWrap style={{ rotateX: downArrowX }}>
          {downHorLine && <S.DownArrowContent></S.DownArrowContent>}
        </S.DownArrowWrap>
        {downUpLine && (
          <S.DownBoxUp
            onAnimationEnd={() => {
              setDownHorLine(true)
            }}
          >
            <S.DownBoxUpLine></S.DownBoxUpLine>
          </S.DownBoxUp>
        )}
        <S.DownBoxLeft>
          {downHorLine && <S.DownBoxHor onAnimationEnd={() => setDownBottomLine(true)}></S.DownBoxHor>}
        </S.DownBoxLeft>
        <S.DownBoxRight>{downHorLine && <S.DownBoxHor></S.DownBoxHor>}</S.DownBoxRight>
        <S.DownBoxBottomContainer>
          {downBottomLine && (
            <S.DownBoxBottomLeft
              onAnimationEnd={() => {
                if (mQuery) {
                  setVerLine(true)
                } else {
                  setArrowHor(true)
                }
              }}
            ></S.DownBoxBottomLeft>
          )}
          {downBottomLine && <S.DownBoxBottomRight></S.DownBoxBottomRight>}
        </S.DownBoxBottomContainer>
      </S.DownArrowPanel>
      <S.CenterRightLine>
        {verLine && <S.CenterRightLineContent onAnimationEnd={() => setArrowHor(true)}></S.CenterRightLineContent>}
      </S.CenterRightLine>
      <S.CenterLeftLine>{verLine && <S.CenterLeftLineContent></S.CenterLeftLineContent>}</S.CenterLeftLine>
      <S.ArrowRightContainer onClick={handleNextClick}>
        {arrowVer && (
          <S.ArrowRightWrapper>
            <S.ArrowRightContent></S.ArrowRightContent>
          </S.ArrowRightWrapper>
        )}
        <S.ArrowRightBorderLeft>
          {arrowHor && (
            <S.ArrowRightBorderLeftUp onAnimationEnd={() => setArrowVer(true)}>
              <S.ArrowRightBorderLeftLine></S.ArrowRightBorderLeftLine>
            </S.ArrowRightBorderLeftUp>
          )}
          {arrowHor && (
            <S.ArrowRightBorderLeftBottom>
              <S.ArrowRightBorderLeftLine></S.ArrowRightBorderLeftLine>
            </S.ArrowRightBorderLeftBottom>
          )}
        </S.ArrowRightBorderLeft>
        {arrowVer && (
          <S.ArrowRightBorderTop onAnimationEnd={() => setArrowHorRight(true)}>
            <S.ArrowRightBorderHorContent></S.ArrowRightBorderHorContent>
          </S.ArrowRightBorderTop>
        )}
        {arrowVer && (
          <S.ArrowRightBorderBottom>
            <S.ArrowRightBorderHorContent></S.ArrowRightBorderHorContent>
          </S.ArrowRightBorderBottom>
        )}

        <S.ArrowRightBorderRight>
          {arrowHorRight && <S.ArrowRightBorderRightUp></S.ArrowRightBorderRightUp>}
          {arrowHorRight && <S.ArrowRightBorderRightBottom></S.ArrowRightBorderRightBottom>}
        </S.ArrowRightBorderRight>
      </S.ArrowRightContainer>
      <S.ArrowLeftContainer onClick={handlePreClick}>
        {arrowVer && (
          <S.ArrowLeftWrapper>
            <S.ArrowLeftContent></S.ArrowLeftContent>
          </S.ArrowLeftWrapper>
        )}
        <S.ArrowLeftBorderRight>
          {arrowHor && (
            <S.ArrowLeftBorderRightUp>
              <S.ArrowLeftBorderRightUpContent
                onAnimationEnd={() => setArrowVer(true)}
              ></S.ArrowLeftBorderRightUpContent>
            </S.ArrowLeftBorderRightUp>
          )}
          {arrowHor && (
            <S.ArrowLeftBorderRightBottom>
              <S.ArrowLeftBorderRightBottomContent></S.ArrowLeftBorderRightBottomContent>
            </S.ArrowLeftBorderRightBottom>
          )}
        </S.ArrowLeftBorderRight>
        {arrowVer && (
          <S.ArrowLeftBorderTop onAnimationEnd={() => setArrowHorRight(true)}>
            <S.ArrowLeftBorderHorContent></S.ArrowLeftBorderHorContent>
          </S.ArrowLeftBorderTop>
        )}
        {arrowVer && (
          <S.ArrowLeftBorderBottom>
            <S.ArrowLeftBorderHorContent></S.ArrowLeftBorderHorContent>
          </S.ArrowLeftBorderBottom>
        )}

        <S.ArrowLeftBorderLeft>
          {arrowHorRight && (
            <S.ArrowLeftBorderLeftUp
              onAnimationEnd={() => {
                setSliserShow(true)
              }}
            ></S.ArrowLeftBorderLeftUp>
          )}
          {arrowHorRight && <S.ArrowLeftBorderLeftBottom></S.ArrowLeftBorderLeftBottom>}
        </S.ArrowLeftBorderLeft>
      </S.ArrowLeftContainer>
    </>
  )
}
