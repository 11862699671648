import { TransactionResponse } from '@ethersproject/providers'
import { parseEther } from 'ethers/lib/utils'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useDCultContract } from 'hooks/useContract'
import { useCallback } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { calculateGasMargin } from 'utils/calculateGasMargin'

export function useDepositCallback(): (amount: string | undefined) => undefined | Promise<string> {
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const deCUContract = useDCultContract(true)

  return useCallback(
    (amount: string | undefined) => {
      if (!library || !chainId || !account || !amount) return undefined
      if (!deCUContract) throw new Error('No deCU Contract!')
      const depositAmount = parseEther(amount)
      return deCUContract.estimateGas.deposit(0, depositAmount, {}).then((estimatedGasLimit) => {
        return deCUContract
          .deposit(0, depositAmount, { gasLimit: calculateGasMargin(estimatedGasLimit) })
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              type: TransactionType.DEPOSIT,
            })
            return response.hash
          })
      })
    },
    [account, addTransaction, chainId, library, deCUContract]
  )
}

export function useWithdrawCallback(): (amount: string | undefined) => undefined | Promise<string> {
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const deCUContract = useDCultContract(true)

  return useCallback(
    (amount: string | undefined) => {
      if (!library || !chainId || !account || !amount) return undefined
      if (!deCUContract) throw new Error('No deCU Contract!')
      const withdrawAmount = parseEther(amount)
      return deCUContract.estimateGas.withdraw(0, withdrawAmount, {}).then((estimatedGasLimit) => {
        return deCUContract
          .withdraw(0, withdrawAmount, { gasLimit: calculateGasMargin(estimatedGasLimit) })
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              type: TransactionType.WITHDRAW,
            })
            return response.hash
          })
      })
    },
    [account, addTransaction, chainId, library, deCUContract]
  )
}

export function useClaimCallback(): () => undefined | Promise<string> {
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const deCUContract = useDCultContract(true)

  return useCallback(() => {
    if (!library || !chainId || !account) return undefined
    if (!deCUContract) throw new Error('No deCU Contract!')
    return deCUContract.estimateGas.claimINCU(0, {}).then((estimatedGasLimit) => {
      return deCUContract
        .claimINCU(0, { gasLimit: calculateGasMargin(estimatedGasLimit) })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            type: TransactionType.CLAIM,
            recipient: account,
          })
          return response.hash
        })
    })
  }, [account, addTransaction, chainId, library, deCUContract])
}
