import { Trans } from '@lingui/macro'
import { SpringValue } from '@react-spring/web'
import { useHistory } from 'react-router-dom'

import * as S from './styles'

export const DecuPanel = ({ decuPanelHeight }: { decuPanelHeight: SpringValue<string> }) => {
  const history = useHistory()
  return (
    <S.DecuPanel style={{ height: decuPanelHeight }}>
      <S.SlideContainer>
        <S.Dapp></S.Dapp>
        <S.SliderLeftTabPanel>
          <S.SliderTabLine></S.SliderTabLine>
          <S.SliderTabContentDapp
            onClick={() => {
              history.push('/stake')
            }}
          >
            <S.SliderTabLeftText>
              <Trans>Stake</Trans>
            </S.SliderTabLeftText>
          </S.SliderTabContentDapp>
        </S.SliderLeftTabPanel>
        <S.SliderRightTabPanel>
          <S.SliderTabDot></S.SliderTabDot>
          <S.SliderTabLine></S.SliderTabLine>
          <S.SliderTabContentDapp
            onClick={() => {
              history.push('/vote')
            }}
          >
            <S.SliderTabRightText>
              <Trans>Proposals</Trans>
            </S.SliderTabRightText>
          </S.SliderTabContentDapp>
        </S.SliderRightTabPanel>
      </S.SlideContainer>
    </S.DecuPanel>
  )
}
