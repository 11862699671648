import Loader from 'components/Loader'
import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader'
import { lazy, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components/macro'

// import GoogleAnalyticsReporter from '../components/Analytics/GoogleAnalyticsReporter'
import ErrorBoundary from '../components/ErrorBoundary'
// import Header from '../components/Header'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import Home from './Home'
import { RedirectPathToHomeOnly } from './Home/redirects'
import Stake from './Stake'

const Vote = lazy(() => import('./Vote'))

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  flex: 1;
  z-index: 1;
`

// const HeaderWrapper = styled.div`
//   ${({ theme }) => theme.flexRowNoWrap}
//   width: 100%;
//   justify-content: space-between;
//   position: fixed;
//   top: 0;
//   z-index: 4;
// `

const Marginer = styled.div`
  margin-top: 5rem;
`

export default function App() {
  return (
    <ErrorBoundary>
      {/* <Route component={GoogleAnalyticsReporter} /> */}
      <Route component={DarkModeQueryParamReader} />
      <Route component={ApeModeQueryParamReader} />
      <Web3ReactManager>
        <AppWrapper>
          {/* <HeaderWrapper>
            <Header />
          </HeaderWrapper> */}
          <BodyWrapper>
            <Popups />
            <Suspense fallback={<Loader />}>
              <Switch>
                <Route strict path="/vote" component={Vote} />
                <Route strict path="/stake" component={Stake} />
                <Route exact strict path="/create-proposal">
                  <Redirect to="/vote/create-proposal" />
                </Route>
                <Route exact strict path="/" component={Home} />

                <Route component={RedirectPathToHomeOnly} />
              </Switch>
            </Suspense>
            <Marginer />
          </BodyWrapper>
        </AppWrapper>
      </Web3ReactManager>
    </ErrorBoundary>
  )
}
