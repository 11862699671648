import { VoteOption } from 'state/governance/hooks'

interface SerializableTransactionReceipt {
  to: string
  from: string
  contractAddress: string
  transactionIndex: number
  blockHash: string
  transactionHash: string
  blockNumber: number
  status?: number
}

/**
 * Be careful adding to this enum, always assign a unique value (typescript will not prevent duplicate values).
 * These values is persisted in state and if you change the value it will cause errors
 */
export enum TransactionType {
  APPROVAL = 0,
  DEPOSIT = 1,
  WITHDRAW = 2,
  CLAIM = 3,
  VOTE = 4,
  DELEGATE = 5,
  SUBMIT_PROPOSAL = 6,
  CANCEL_PROPOSAL = 7,
  QUEUE_PROPOSAL = 8,
  EXECUTE_PROPOSAL = 9,
}

export interface BaseTransactionInfo {
  type: TransactionType
}

export interface DelegateTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.DELEGATE
  delegatee: string
}

export interface CancelProposalTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.CANCEL_PROPOSAL
  governorAddress: string
  proposalId: number
  reason: string
}

export interface QueueProposalTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.QUEUE_PROPOSAL
  proposalId: number
}

export interface ExecuteProposalTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.EXECUTE_PROPOSAL
  proposalId: number
}

export interface VoteTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.VOTE
  governorAddress: string
  proposalId: number
  decision: VoteOption
  reason: string
}

export interface ApproveTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.APPROVAL
  tokenAddress: string
  spender: string
}

export interface DepositTransactionInfo {
  type: TransactionType.DEPOSIT
}

export interface WithdrawLiquidityStakingTransactionInfo {
  type: TransactionType.WITHDRAW
}

export interface ClaimTransactionInfo {
  type: TransactionType.CLAIM
  recipient: string
  uniAmountRaw?: string
}

export interface SubmitProposalTransactionInfo {
  type: TransactionType.SUBMIT_PROPOSAL
}

export type TransactionInfo =
  | ApproveTransactionInfo
  | ClaimTransactionInfo
  | VoteTransactionInfo
  | DelegateTransactionInfo
  | DepositTransactionInfo
  | WithdrawLiquidityStakingTransactionInfo
  | SubmitProposalTransactionInfo
  | CancelProposalTransactionInfo
  | QueueProposalTransactionInfo
  | ExecuteProposalTransactionInfo

export interface TransactionDetails {
  hash: string
  receipt?: SerializableTransactionReceipt
  lastCheckedBlockNumber?: number
  addedTime: number
  confirmedTime?: number
  from: string
  info: TransactionInfo
}
