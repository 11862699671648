import { ListType } from 'pages/Vote/styled'
import { useAppSelector } from 'state/hooks'

export interface ProposalData {
  id: number
  description: ProposalDescription
  proposer: string
  state: ProposalState
  startBlock: string
  endBlock: string
  forVotes: string
  againstVotes: string
  totalVotes: string
  forProportion: string
  againstProportion: string
  voteCount: VoteReceipt
  eta: string
}

export interface ProposalDescription {
  proposerName: string
  proposerHandle: string
  proposerAddress: string
  background: string
  projectName: string
  shortDesc: string
  file: string
  socialChannel: string
  links: string
  projectWallet: string
  range: number
  rate: string
  time: string
  checkbox1: boolean
  checkbox2: boolean
}

export interface CreateProposalData {
  targets: string[]
  values: string[]
  signatures: string[]
  calldatas: string[]
  description: string
}

export interface VoteReceipt {
  hasVoted: boolean
  support: number
  votes: string
}

export interface PropsalInfo {
  id: number
  proposer: string
  eta: string
  startBlock: string
  endBlock: string
  forVotes: string
  againstVotes: string
  abstainVotes: string
  canceled: boolean
  executed: boolean
}

export enum ProposalState {
  UNDETERMINED = -1,
  PENDING,
  ACTIVE,
  CANCELED,
  DEFEATED,
  SUCCEEDED,
  QUEUED,
  EXPIRED,
  EXECUTED,
}

export enum VoteOption {
  Against,
  For,
  Abstain,
}

// get data for all past and active proposals
export function useAllProposalData() {
  const governance = useAppSelector((state) => state.governance)
  const allProposalData = []

  if (governance.proposalCount > 0) {
    for (let i = 1; i <= governance.proposalCount; i++) {
      if (governance.info[i] && governance.details[i] && governance.receipt[i]) {
        const totalVotes =
          parseFloat(governance.info[i].info.forVotes) + parseFloat(governance.info[i].info.againstVotes)
        allProposalData.push({
          id: governance.info[i].info.id,
          description: JSON.parse(governance.details[i].description),
          eta: governance.info[i].info.eta,
          proposer: governance.info[i].info.proposer,
          state: governance.details[i].exstate,
          startBlock: governance.info[i].info.startBlock,
          endBlock: governance.info[i].info.endBlock,
          forVotes: governance.info[i].info.forVotes,
          againstVotes: governance.info[i].info.againstVotes,
          totalVotes: totalVotes.toFixed(3).toLocaleString(),
          voteCount: governance.receipt[i].receipt,
          forProportion:
            totalVotes > 0 ? ((parseFloat(governance.info[i].info.forVotes) / totalVotes) * 100).toFixed(2) : '0.00',
          againstProportion:
            totalVotes > 0
              ? ((parseFloat(governance.info[i].info.againstVotes) / totalVotes) * 100).toFixed(2)
              : '0.00',
        })
      }
    }
  }
  return allProposalData
}

export function useProposalData(id: number) {
  const proposals = useAllProposalData()
  console.log(id)
  return proposals.find((p) => p.id === id)
}

export function useProposalList(type: ListType) {
  const proposals = useAllProposalData()
  console.log('proposals', proposals)
  return proposals.filter((p) => {
    if (type === ListType.PENDING) {
      return (
        p.state === ProposalState.PENDING ||
        p.state === ProposalState.ACTIVE ||
        p.state === ProposalState.SUCCEEDED ||
        p.state === ProposalState.QUEUED
      )
    } else {
      return (
        p.state === ProposalState.CANCELED ||
        p.state === ProposalState.DEFEATED ||
        p.state === ProposalState.EXPIRED ||
        p.state === ProposalState.EXECUTED
      )
    }
  })
}
