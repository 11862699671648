import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BRAVO_START_BLOCK } from 'constants/proposals'

import { ProposalState, PropsalInfo, VoteReceipt } from './hooks'

export interface GovernanceState {
  refresh: boolean
  proposalCount: number
  lastBlockNumber: number
  details: {
    [proposalId: number]: {
      description: string
      exstate: ProposalState
    }
  }
  receipt: {
    [proposalId: number]: {
      receipt: VoteReceipt
    }
  }
  info: {
    [proposalId: number]: {
      info: PropsalInfo
    }
  }
}
const slice = createSlice({
  name: 'governance',
  initialState: {
    refresh: false,
    proposalCount: 0,
    lastBlockNumber: BRAVO_START_BLOCK,
    details: {},
    receipt: {},
    info: {},
  } as GovernanceState,
  reducers: {
    updateProposalCount(state, { payload: { count } }: PayloadAction<{ count: number }>) {
      state.proposalCount = count
    },
    refreshProposals(state, { payload: { refresh } }: PayloadAction<{ refresh: boolean }>) {
      state.refresh = refresh
      console.log('refreshProposals')
    },
    addProposalDescription(
      state,
      { payload: { proposalId, description } }: PayloadAction<{ proposalId: number; description: string }>
    ) {
      if (!state.details[proposalId])
        state.details[proposalId] = {
          description: '',
          exstate: -1,
        }
      state.details[proposalId].description = description
    },
    updateProposalDetail(
      state,
      { payload: { proposalId, detail } }: PayloadAction<{ proposalId: number; detail: PropsalInfo }>
    ) {
      if (!state.info[proposalId])
        state.info[proposalId] = {
          info: {} as PropsalInfo,
        }
      state.info[proposalId].info = detail
    },
    updateProposalState(
      state,
      { payload: { proposalId, curState } }: PayloadAction<{ proposalId: number; curState: ProposalState }>
    ) {
      if (!state.details[proposalId])
        state.details[proposalId] = {
          description: '',
          exstate: -1,
        }
      state.details[proposalId].exstate = curState
    },

    updateProposalReceipt(
      state,
      { payload: { proposalId, receipt } }: PayloadAction<{ proposalId: number; receipt: VoteReceipt }>
    ) {
      if (!state.receipt[proposalId])
        state.receipt[proposalId] = {
          receipt: {} as VoteReceipt,
        }
      state.receipt[proposalId].receipt = receipt
    },
    clearAllPorposalData(state) {
      console.log('clearAllPorposalData', state)
      Object.keys(state.details).forEach((key) => delete state.details[parseInt(key)])
      Object.keys(state.receipt).forEach((key) => delete state.receipt[parseInt(key)])
      Object.keys(state.info).forEach((key) => delete state.info[parseInt(key)])
      state.proposalCount = 0
      state.lastBlockNumber = BRAVO_START_BLOCK
      console.log('clearAllPorposalData', state)
    },
  },
})

export default slice.reducer
export const {
  updateProposalCount,
  addProposalDescription,
  updateProposalDetail,
  updateProposalState,
  updateProposalReceipt,
  refreshProposals,
  clearAllPorposalData,
} = slice.actions
