import { TransactionResponse } from '@ethersproject/providers'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCultContract, useDCultContract } from 'hooks/useContract'
import { useCallback } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { calculateGasMargin } from 'utils/calculateGasMargin'

export function useApproveCallback(): () => undefined | Promise<string> {
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const INCUContract = useCultContract(true)
  const deCUContract = useDCultContract(false)

  return useCallback(() => {
    if (!library || !chainId || !account) return undefined
    if (!INCUContract) throw new Error('No INCU Contract!')
    if (!deCUContract) throw new Error('No deCU Contract!')
    return INCUContract.estimateGas
      .approve(deCUContract?.address, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff', {})
      .then((estimatedGasLimit) => {
        return INCUContract.approve(
          deCUContract?.address,
          '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
          { gasLimit: calculateGasMargin(estimatedGasLimit) }
        ).then((response: TransactionResponse) => {
          addTransaction(response, {
            type: TransactionType.APPROVAL,
            tokenAddress: INCUContract.address,
            spender: deCUContract?.address,
          })
          return response.hash
        })
      })
  }, [library, chainId, account, INCUContract, deCUContract, addTransaction])
}
