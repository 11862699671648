import { createReducer } from '@reduxjs/toolkit'
import { DEFAULT_ACTIVE_DATA_URLS } from 'constants/datas'

import { addData, fetchDaoTokenData, removeAllDatas, removeData } from './actions'

export interface DatasState {
  readonly byUrl: {
    readonly [url: string]: {
      readonly current: any | null
      readonly pendingUpdate: any | null
      readonly loadingRequestId: string | null
      readonly error: string | null
    }
  }
  // this contains the default list of lists from the last time the updateVersion was called, i.e. the app was reloaded
  readonly lastInitializedDefaultListOfLists?: string[]

  // currently active lists
  readonly activeListUrls: string[] | undefined
}

type DataState = DatasState['byUrl'][string]

const NEW_DATA_STATE: DataState = {
  error: null,
  current: null,
  loadingRequestId: null,
  pendingUpdate: null,
}

type Mutable<T> = { -readonly [P in keyof T]: T[P] extends ReadonlyArray<infer U> ? U[] : T[P] }

const initialState: DatasState = {
  lastInitializedDefaultListOfLists: DEFAULT_ACTIVE_DATA_URLS,
  byUrl: {
    ...DEFAULT_ACTIVE_DATA_URLS.reduce<Mutable<DatasState['byUrl']>>((memo, dataUrl) => {
      memo[dataUrl] = NEW_DATA_STATE
      return memo
    }, {}),
  },
  activeListUrls: DEFAULT_ACTIVE_DATA_URLS,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(fetchDaoTokenData.pending, (state, { payload: { requestId, url } }) => {
      const current = state.byUrl[url]?.current ?? null
      const pendingUpdate = state.byUrl[url]?.pendingUpdate ?? null

      state.byUrl[url] = {
        current,
        pendingUpdate,
        loadingRequestId: requestId,
        error: null,
      }
    })
    .addCase(fetchDaoTokenData.fulfilled, (state, { payload: { requestId, data, url } }) => {
      const current = state.byUrl[url]?.current
      const loadingRequestId = state.byUrl[url]?.loadingRequestId

      // no-op if update does nothing
      if (current) {
        if (loadingRequestId === null || loadingRequestId === requestId) {
          state.byUrl[url] = {
            current,
            pendingUpdate: data,
            loadingRequestId: null,
            error: null,
          }
        }
      } else {
        state.byUrl[url] = {
          current: data,
          pendingUpdate: null,
          loadingRequestId: null,
          error: null,
        }
      }
    })
    .addCase(fetchDaoTokenData.rejected, (state, { payload: { url, requestId, errorMessage } }) => {
      if (state.byUrl[url]?.loadingRequestId !== requestId) {
        // no-op since it's not the latest request
        return
      }

      state.byUrl[url] = {
        current: state.byUrl[url].current ? state.byUrl[url].current : null,
        pendingUpdate: null,
        loadingRequestId: null,
        error: errorMessage,
      }
    })
    .addCase(addData, (state, { payload: url }) => {
      if (!state.byUrl[url]) {
        state.byUrl[url] = NEW_DATA_STATE
      }
    })
    .addCase(removeData, (state, { payload: url }) => {
      if (state.byUrl[url]) {
        delete state.byUrl[url]
      }
    })
    .addCase(removeAllDatas, (state) => {
      if (state.activeListUrls) {
        state.activeListUrls.forEach(
          (url) =>
            (state.byUrl[url] = {
              current: null,
              pendingUpdate: null,
              loadingRequestId: null,
              error: null,
            })
        )
      }
    })
)
