import { animated } from '@react-spring/web'
import styled, { keyframes } from 'styled-components/macro'
import { ExternalLink } from 'theme'

const HorizontalKeyframes = keyframes`
  from {
    height 0%;
  }

  to {
     height 100%;
  }
`
const Horizontal50Keyframes = keyframes`
  from {
    height 0%;
  }

  to {
     height 50%;
  }
`
const VerticalKeyframes = keyframes`
  from {
    width 0%;
  }

  to {
     width 100%;
  }
`
const Vertical50Keyframes = keyframes`
  from {
    width 0%;
  }

  to {
     width 50%;
  }
`

const animateSecond = '0.2s'

export const HorLine1 = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 5;
  display: block;
  width: 2px;
  height: 3vh;
  margin-right: auto;
  margin-left: auto;
`

export const HorLineContent = styled.div`
  width: 2px;
  animation: ${HorizontalKeyframes} ${animateSecond};
  height: 100%;
  background-color: hsla(0, 0%, 100%, 0.85);
`
export const HorLine6 = styled.div`
  position: absolute;
  left: 0px;
  top: 7.3vh;
  right: 0px;
  z-index: 3;
  display: block;
  width: 2px;
  height: 29.8vh;
  margin-right: auto;
  margin-left: auto;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    top: 7.1vh;
    height: 29.9vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 12.1vh;
    height: 12.9vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    top: 9.1vh;
    height: 21.9vh;
  `};
`

export const MenuDiv = styled.div`
  position: absolute;
  left: 0px;
  top: 3vh;
  right: 0px;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 2.5vw;
  height: 4.4vh;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.85;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 6.5vw;
    height: 4.2vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 9.2vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 10.5vw;
    height: 6.2vh;
  `};
`

export const HamWrapper = styled(animated.div)`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 20px;
  height: 20px;
  margin: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  transform-style: preserve-3d;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 2px;
    width: 16px;
    height: 16px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    top: 0px;
  `};
`

export const HamVerLine = styled(animated.div)`
  width: 100%;
  height: 2px;
  background-color: #fff;
  transform-style: preserve-3d;
`
export const HamVerLine2 = styled(animated.div)`
  width: 100%;
  height: 2px;
  background-color: #fff;
  transform-style: preserve-3d;
`

export const HamLineMid = styled.div`
  position: relative;
  width: 100%;
  height: 2px;
`
export const HamLineMidContent1 = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #fff;
`

export const HamLineMidContent = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #fff;
  transform-style: preserve-3d;
`

export const MenuUpBorder = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 3;
  display: block;
  width: 100%;
  height: 2px;
`
export const MenuUpContent = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  display: block;
  width: 100%;
  height: 2px;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  animation: ${VerticalKeyframes} ${animateSecond};
`

export const MenuLeftBorder = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 3;
  display: block;
  width: 2px;
  height: 100%;
`
export const MenuLeftContent = styled.div`
  width: 2px;
  height: 100%;
  background-color: #fff;
  animation: ${HorizontalKeyframes} ${animateSecond};
`
export const MenuRightBorder = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 3;
  display: block;
  width: 2px;
  height: 100%;
`
export const MenuRightContent = styled.div`
  width: 2px;
  height: 100%;
  background-color: #fff;
  animation: ${HorizontalKeyframes} ${animateSecond};
`

export const MenuBottomBorder = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 3;
  display: block;
  width: 100%;
  height: 2px;
`
export const MenuBottomContent1 = styled.div`
  width: 50%;
  height: 2px;
  float: left;
  background-color: #fff;
  animation: ${Vertical50Keyframes} ${animateSecond};
`
export const MenuBottomContent2 = styled.div`
  width: 50%;
  height: 2px;
  float: right;
  background-color: #fff;
  animation: ${Vertical50Keyframes} ${animateSecond};
`

export const MenuRightFirstLine = styled.div`
  position: absolute;
  left: 4.3vw;
  top: 5.2vh;
  right: 0vw;
  z-index: 5;
  display: block;
  width: 2vw;
  height: 2px;
  margin-right: auto;
  margin-left: auto;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    left: 9vw;
    top: 5vh;
    width: 2.5vw;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 7.6vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    left: 14vw;
    top: 6.1vh;
    width: 4vw;
  `};
`

export const MenuRightFirstLineContent = styled(animated.div)`
  width: 100%;
  height: 2px;
  float: left;
  background-color: hsla(0, 0%, 100%, 0.85);
`

export const MenuRightFirstPanel = styled(animated.div)`
  position: absolute;
  left: 8.6vw;
  top: 3vh;
  right: 0vw;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 2.5vw;
  height: 4.4vh;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.85;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    left: 18vw;
    width: 6.5vw;
    height: 4.2vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 9.2vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    left: 28vw;
    width: 10.5vw;
    height: 6.2vh;
    background-size: 20px;
  `};
`
export const MenuLink = styled(ExternalLink)`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
`

export const MenuHomeLink = styled.a`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
`

export const MenuRightSecondLine = styled.div`
  position: absolute;
  left: 13vw;
  top: 5.2vh;
  right: 0vw;
  z-index: 5;
  display: block;
  width: 2vw;
  height: 2px;
  margin-right: auto;
  margin-left: auto;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    left: 27vw;
    top: 5vh;
    width: 2.5vw;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 7.6vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    left: 42vw;
    top: 6.1vh;
    width: 4vw;
  `};
`

export const MenuRightSecondLineContent = styled(animated.div)`
  width: 100%;
  height: 2px;
  float: left;
  background-color: hsla(0, 0%, 100%, 0.85);
`

export const MenuRightSecondPanel = styled(animated.div)`
  position: absolute;
  left: 17.3vw;
  top: 3vh;
  right: 0vw;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 2.5vw;
  height: 4.4vh;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.85;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    left: 36vw;
    width: 6.5vw;
    height: 4.2vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 9.2vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    left: 56vw;
    width: 10.5vw;
    height: 6.2vh;
  `};
`

export const MenuLeftFirstLine = styled.div`
  position: absolute;
  left: 0px;
  top: 5.2vh;
  right: 4.3vw;
  z-index: 5;
  display: block;
  width: 2vw;
  height: 2px;
  margin-right: auto;
  margin-left: auto;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    top: 5vh;
    right: 9vw;
    width: 2.5vw;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 7.6vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    top: 6.1vh;
    right: 14vw;
    width: 4vw;
  `};
`

export const MenuLeftFirstLineContent = styled(animated.div)`
  width: 100%;
  height: 2px;
  float: right;
  background-color: hsla(0, 0%, 100%, 0.85);
`

export const MenuLeftFirstPanel = styled(animated.div)`
  position: absolute;
  left: 0px;
  top: 3vh;
  right: 8.6vw;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 2.5vw;
  height: 4.4vh;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.85;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    right: 18vw;
    width: 6.5vw;
    height: 4.2vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 9.2vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    right: 28vw;
    width: 10.5vw;
    height: 6.2vh;
  `};
`
export const MenuLeftSecondLine = styled.div`
  position: absolute;
  left: 0px;
  top: 5.2vh;
  right: 13vw;
  z-index: 5;
  display: block;
  width: 2vw;
  height: 2px;
  margin-right: auto;
  margin-left: auto;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    top: 5vh;
    right: 27vw;
    width: 2.5vw;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 7.6vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    top: 6.1vh;
    right: 42vw;
    width: 4vw;
  `};
`

export const MenuLeftSecondLineContent = styled(animated.div)`
  width: 100%;
  height: 2px;
  float: right;
  background-color: hsla(0, 0%, 100%, 0.85);
`

export const MenuLeftSecondPanel = styled(animated.div)`
  position: absolute;
  left: 0px;
  top: 3vh;
  right: 17.3vw;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 2.5vw;
  height: 4.4vh;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.85;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    right: 36vw;
    width: 6.5vw;
    height: 4.2vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 9.2vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    right: 56vw;
    width: 10.5vw;
    height: 6.2vh;
  `};
`

export const MenuBorderTop = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 3;
  display: block;
  width: 100%;
  height: 2px;
`

export const MenuRightBorderVer = styled(animated.div)`
  width: 100%;
  height: 2px;
  float: left;
  background-color: #fff;
`

export const MenuLeftBorderVer = styled(animated.div)`
  width: 100%;
  height: 2px;
  float: right;
  background-color: #fff;
`

export const MenuBorderLeft = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 3;
  display: block;
  width: 2px;
  height: 100%;
`

export const MenuBorderLeftUp = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 2px;
  height: 50%;
`

export const MenuLeftBorderLeftUp = styled(animated.div)`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 2px;
  height: 50%;
  background-color: #fff;
`

export const MenuBorderLeftUpContent = styled(animated.div)`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 2px;
  height: 100%;
  background-color: #fff;
`

export const MenuBorderLeftBottom = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 2px;
  height: 50%;
`

export const MenuLeftBorderLeftBottom = styled(animated.div)`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 2px;
  height: 50%;
  background-color: #fff;
`

export const MenuBorderLeftBottomContent = styled(animated.div)`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 2px;
  height: 100%;
  background-color: #fff;
`

export const MenuBorderBottom = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 3;
  display: block;
  width: 100%;
  height: 2px;
`
export const MenuBorderRight = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 3;
  display: block;
  width: 2px;
  height: 100%;
`

export const MenuBorderRightUp = styled(animated.div)`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 2px;
  height: 50%;
  background-color: #fff;
`

export const MenuLeftBorderRightUp = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 2px;
  height: 50%;
`

export const MenuLeftBorderRightUpContent = styled(animated.div)`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 2px;
  height: 100%;
  background-color: #fff;
`

export const MenuBorderRightBottom = styled(animated.div)`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 2px;
  height: 50%;
  background-color: #fff;
`

export const MenuLeftBorderRightBottom = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 2px;
  height: 50%;
`

export const MenuLeftBorderRightBottomContent = styled(animated.div)`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 2px;
  height: 100%;
  background-color: #fff;
`

export const MenuIcon = styled(animated.div)`
  position: absolute;
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
  display: block;
  width: 26px;
  height: 20px;
  margin: auto;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 20px;
    height: 16px;
  `};
`

export const MenuHomeIcon = styled(animated.div)`
  position: absolute;
  width: 23px;
  height: 20px;
  margin: auto;
  padding-bottom: 25px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 20px;
    height: 16px;
    padding-bottom: 20px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding-bottom: 20px;
  `};
`

export const MenuIconWrapper = styled.div`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 20px;
    height: 16px;
    padding-bottom: 16px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding-bottom: 10px;
  `};
`
export const MenuImg = styled.img`
  width: 100%;
  vertical-align: middle;
  display: inline-block;
`

export const ParticlesBox = styled.div`
  position: absolute;
  left: 0px;
  top: 37vh;
  right: 0px;
  z-index: 3;
  display: block;
  width: 15vw;
  height: 26vh;
  margin-right: auto;
  margin-left: auto;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 40vw;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 25vh;
    height: 46vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    top: 31vh;
    width: 50vw;
    height: 32vh;
  `};
`

export const ParticlesUp = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 3;
  display: block;
  width: 100%;
  height: 2px;
  margin-right: auto;
  margin-left: auto;
  background-color: hsla(0, 0%, 100%, 0.85);
  animation: ${VerticalKeyframes} ${animateSecond};
`

export const ParticlesLeft = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 3;
  width: 2px;
  height: 100%;
  background-color: hsla(0, 0%, 100%, 0.85);
  animation: ${HorizontalKeyframes} ${animateSecond};
`
export const ParticlesRight = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 3;
  width: 2px;
  height: 100%;
  background-color: hsla(0, 0%, 100%, 0.85);
  animation: ${HorizontalKeyframes} ${animateSecond};
`
export const ParticlesBottom = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 3;
  width: 100%;
  height: 2px;
`
export const ParticlesBottomLeft = styled.div`
  width: 50%;
  height: 2px;
  float: left;
  background-color: hsla(0, 0%, 100%, 0.85);
  animation: ${Vertical50Keyframes} ${animateSecond};
`
export const ParticlesBottomRight = styled.div`
  width: 50%;
  height: 2px;
  float: right;
  background-color: hsla(0, 0%, 100%, 0.85);
  animation: ${Vertical50Keyframes} ${animateSecond};
`

export const ParticlesContent = styled.div`
  display: block;
  padding: 20px 10px 0px;
  font-family: Coda, sans-serif;
  color: #fff;
  font-size: 1.8em;
  line-height: 1.5em;
  text-align: center;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding-top: 40px;
    font-size: 1.2em;
    line-height: 1.5em;
  `};
`

export const HorLine7 = styled.div`
  position: absolute;
  left: 0px;
  top: 63vh;
  right: 0px;
  z-index: 3;
  display: block;
  width: 2px;
  height: 27vh;
  margin-right: auto;
  margin-left: auto;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 71vh;
    height: 15vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    top: 63vh;
    height: 26vh;
  `};
`

export const DownArrowPanel = styled.div`
  position: absolute;
  left: 0px;
  top: 90vh;
  right: 0px;
  z-index: 3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 2.5vw;
  height: 4.4vh;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.85;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 6.5vw;
    height: 4.2vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 86vh;
    height: 9.2vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    top: 89vh;
    width: 10.5vw;
    height: 6.2vh;
  `};
`

export const DownArrowWrap = styled(animated.div)`
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
`

export const DownArrowContent = styled.div`
  position: relative;
  width: 15px;
  height: 15px;
  margin-bottom: 10px;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 10px;
    height: 10px;
    margin-bottom: 5px;
  `};
`

export const DownBoxUp = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 3;
  display: block;
  width: 100%;
  height: 2px;
  animation: ${VerticalKeyframes} ${animateSecond};
  margin-right: auto;
  margin-left: auto;
  animation: ${VerticalKeyframes} ${animateSecond};
`

export const DownBoxUpLine = styled.div`
  width: 100%;
  height: 2px;
  background-color: #fff;
`

export const DownBoxLeft = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 3;
  display: block;
  width: 2px;
  height: 100%;
`

export const DownBoxHor = styled.div`
  width: 2px;
  height: 100%;
  background-color: #fff;
  animation: ${HorizontalKeyframes} ${animateSecond};
`
export const DownBoxRight = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 3;
  display: block;
  width: 2px;
  height: 100%;
`

export const DownBoxBottomContainer = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 3;
  display: block;
  width: 100%;
  height: 2px;
`

export const DownBoxBottomLeft = styled.div`
  width: 50%;
  height: 2px;
  float: left;
  background-color: #fff;
  animation: ${Vertical50Keyframes} ${animateSecond};
`

export const DownBoxBottomRight = styled.div`
  width: 50%;
  height: 2px;
  float: right;
  background-color: #fff;
  animation: ${Vertical50Keyframes} ${animateSecond};
`

export const CenterRightLine = styled.div`
  position: absolute;
  left: 34.4vw;
  top: 50vh;
  right: 0px;
  z-index: 3;
  display: block;
  width: 19.4vw;
  height: 2px;
  margin-right: auto;
  margin-left: auto;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    left: 58vw;
    width: 18vw;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 47vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    left: 60vw;
    width: 10vw;
    display: none;
  `};
`

export const CenterRightLineContent = styled.div`
  width: 100%;
  height: 2px;
  float: left;
  background-color: hsla(0, 0%, 100%, 0.85);
  animation: ${VerticalKeyframes} ${animateSecond};
`

export const CenterLeftLine = styled.div`
  position: absolute;
  left: 0px;
  top: 50vh;
  right: 34.4vw;
  z-index: 3;
  display: block;
  width: 19.4vw;
  height: 2px;
  margin-right: auto;
  margin-left: auto;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    right: 58vw;
    width: 18vw;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 47vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    right: 60vw;
    width: 10vw;
    display: none;
  `};
`

export const CenterLeftLineContent = styled.div`
  width: 100%;
  height: 2px;
  float: right;
  background-color: hsla(0, 0%, 100%, 0.85);
  animation: ${VerticalKeyframes} ${animateSecond};
`

export const SliderPanel = styled.div`
  position: absolute;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100vh;
  background-color: transparent;
`

export const SlideContainer = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
  white-space: normal;
  text-align: left;
`

export const SliderDesPanel = styled.div`
  position: absolute;
  left: 275px;
  top: 69vh;
  right: 0px;
  z-index: 3;
  display: block;
  width: 250px;
  height: 80px;
  margin-right: auto;
  margin-left: auto;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    top: 71vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    left: 265px;
    top: 74vh;
    width: 200px;
    height: 90px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    left: 155px;
    top: 66vh;
    width: 120px;
  `};
`

export const SliderDesContent = styled.div`
  display: block;
  margin-top: -10px;
  font-family: Coda, sans-serif;
  color: hsla(0, 0%, 100%, 0.36);
  font-size: 0.8em;
  line-height: 1.4em;
  text-align: left;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 0.7em;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    left: 145px;
    top: 68.5vh;
    width: 120px;
    margin-top: 0px;
    color: hsla(0, 0%, 100%, 0.3);
  `};
`

export const SliderTabPanel = styled.div`
  position: absolute;
  left: 150px;
  top: 81vh;
  right: 0px;
  z-index: 4;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 160px;
  height: 5vh;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    left: 170px;
    top: 74vh;
    height: 10vh;
    -webkit-transform: rotateX(0deg) rotateY(-180deg) rotateZ(0deg);
    transform: rotateX(0deg) rotateY(-180deg) rotateZ(0deg);
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    left: 153px;
    width: 144px;
  `};
`

export const SliderLeftTabPanel = styled.div`
  position: absolute;
  left: 150px;
  top: 81vh;
  right: 0px;
  z-index: 4;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 160px;
  height: 5vh;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(0deg) rotateY(-180deg) rotateZ(0deg);
  transform: rotateX(0deg) rotateY(-180deg) rotateZ(0deg);
  ${({ theme }) => theme.mediaWidth.upToSmall`
    left: 170px;
    top: 74vh;
    height: 10vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    left: 153px;
    width: 144px;
  `};
`

export const SliderRightTabPanel = styled.div`
  position: absolute;
  left: 150px;
  top: 81vh;
  right: 0px;
  z-index: 4;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 160px;
  height: 5vh;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 74vh;
    height: 10vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    left: 133px;
    width: 144px;
  `};
`

export const SliderTabDot = styled.div`
  display: block;
  width: 10px;
  height: 10px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 5px;
  background-color: #fff;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 78vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    top: 77.6vh;
  `};
`
export const SliderTabLine = styled.div`
  display: block;
  width: 2vw;
  height: 2px;
  margin-right: auto;
  margin-left: auto;
  background-color: hsla(0, 0%, 100%, 0.85);
  ${({ theme }) => theme.mediaWidth.upToMedium`
    left: 3.75vw;
    width: 4vw;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    left: 0vw;
    top: 78.5vh;
    right: 3.75vw;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    top: 78vh;
    width: 4.5vw;
  `};
`

export const SliderTabContent = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 130px;
  height: 4.5vh;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: hsla(0, 0%, 100%, 0.85);
  -webkit-transition: background-color 200ms ease, color 200ms ease;
  transition: background-color 200ms ease, color 200ms ease;
  font-family: Exo, sans-serif;
  color: hsla(0, 0%, 100%, 0.85);
  font-weight: 700;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    left: 24vw;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    left: 0vw;
    top: 74vh;
    right: 29.5vw;
    height: 9.2vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    top: 74vh;
    right: 45vw;
    height: 8.2vh;
  `};
`

export const SliderTabContentDapp = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 130px;
  height: 4.5vh;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: hsla(0, 0%, 100%, 0.85);
  -webkit-transition: background-color 200ms ease, color 200ms ease;
  transition: background-color 200ms ease, color 200ms ease;
  font-family: Exo, sans-serif;
  color: hsla(0, 0%, 100%, 0.85);
  font-weight: 700;
  cursor: pointer;
  &:hover {
    background-color: #fff;
    color: #222;
    font-weight: 800;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    left: 24vw;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    left: 0vw;
    top: 74vh;
    right: 29.5vw;
    height: 9.2vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    top: 74vh;
    right: 45vw;
    height: 8.2vh;
  `};
`

export const SliderTabText = styled.div`
  text-align: center;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    -webkit-transform: rotateX(0deg) rotateY(-180deg) rotateZ(0deg);
    transform: rotateX(0deg) rotateY(-180deg) rotateZ(0deg);
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    -webkit-transform: rotateX(0deg) rotateY(-180deg) rotateZ(0deg);
    transform: rotateX(0deg) rotateY(-180deg) rotateZ(0deg);
  `};
`

export const SliderTabLeftText = styled.div`
  text-align: center;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(0deg) rotateY(-180deg) rotateZ(0deg);
  transform: rotateX(0deg) rotateY(-180deg) rotateZ(0deg);
`

export const SliderTabRightText = styled.div`
  text-align: center;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
`

export const Invision = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 100vh;
  background-image: url('../images/Home.png');
  background-position: 50% 50%;
  background-size: 50%;
  background-repeat: no-repeat;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    background-size: 80%;
  `};
`
export const Incu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 100vh;
  background-image: url('../images/INCU.png');
  background-position: 50% 50%;
  background-size: 50%;
  background-repeat: no-repeat;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    background-size: 80%;
  `};
`
export const Decu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 100vh;
  background-image: url('../images/deCU.png');
  background-position: 50% 50%;
  background-size: 50%;
  background-repeat: no-repeat;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    background-size: 80%;
  `};
`
export const Dapp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 100vh;
  background-image: url('../images/Home.png');
  background-position: 50% 50%;
  background-size: 50%;
  background-repeat: no-repeat;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    background-size: 80%;
  `};
`
export const Guardian = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 100vh;
  background-image: url('../images/Guardian.png');
  background-position: 50% 50%;
  background-size: 50%;
  background-repeat: no-repeat;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    background-size: 80%;
  `};
`

export const Proposal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 100vh;
  background-image: url('../images/Proposal.png');
  background-position: 50% 50%;
  background-size: 50%;
  background-repeat: no-repeat;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    background-size: 80%;
  `};
`

export const ArrowRightContainer = styled.div`
  position: absolute;
  left: 59.25vw;
  top: 0vh;
  right: 0px;
  bottom: 0px;
  z-index: 3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 5.6vw;
  height: 9.4vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  text-align: center;
  margin: auto;
  align-items: center;
  opacity: 0.85;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    left: 82vw;
    width: 6.5vw;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    left: 82vw;
    top: -5.8vh;
    height: 13.2vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    left: 30vw;
    top: 84.25vh;
    width: 10.5vw;
    height: 6.2vh;
  `};
`

export const ArrowRightWrapper = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  margin-right: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-right: 8px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-top: -2px;
    margin-right: 4px;
  `};
`

export const ArrowRightContent = styled.div`
  position: relative;
  width: 26px;
  height: 26px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 15px;
    height: 15px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 10px;
    height: 10px;
  `};
`
export const ArrowRightBorderLeft = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 3;
  display: block;
  width: 2px;
  height: 100%;
`

export const ArrowRightBorderLeftUp = styled.div`
  position: absolute;
  left: 0px;
  bottom: 50%;
  right: 0px;
  width: 2px;
  height: 50%;
  animation: ${Horizontal50Keyframes} ${animateSecond} ease-out;
`

export const ArrowRightBorderLeftBottom = styled.div`
  position: absolute;
  left: 0px;
  top: 50%;
  right: 0px;
  width: 2px;
  height: 50%;
  animation: ${Horizontal50Keyframes} ${animateSecond} ease-out;
`

export const ArrowRightBorderLeftLine = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 2px;
  height: 100%;
  background-color: #fff;
`

export const ArrowRightBorderTop = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 3;
  display: block;
  width: 100%;
  height: 2px;
`

export const ArrowRightBorderHorContent = styled.div`
  width: 100%;
  height: 2px;
  float: left;
  background-color: #fff;
  animation: ${VerticalKeyframes} ${animateSecond};
`

export const ArrowRightBorderBottom = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 3;
  display: block;
  width: 100%;
  height: 2px;
`
export const ArrowRightBorderRight = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 3;
  display: block;
  width: 2px;
  height: 100%;
`

export const ArrowRightBorderRightUp = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 2px;
  height: 50%;
  background-color: #fff;
  animation: ${Horizontal50Keyframes} ${animateSecond};
`

export const ArrowRightBorderRightBottom = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 2px;
  height: 50%;
  background-color: #fff;
  animation: ${Horizontal50Keyframes} ${animateSecond};
`

export const ArrowLeftContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0.2vh;
  right: 59.25vw;
  bottom: 0px;
  z-index: 3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 5.6vw;
  height: 9.4vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.85;
  cursor: pointer;
  text-align: center;
  margin: auto;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    right: 82vw;
    width: 6.5vw;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: -5.8vh;
    right: 82vw;
    height: 13.2vh;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    top: 84.25vh;
    right: 30vw;
    width: 10.5vw;
    height: 6.2vh;
  `};
`

export const ArrowLeftWrapper = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  margin-left: 15px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 8px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-top: -2px;
    margin-left: 4px;
  `};
`

export const ArrowLeftContent = styled.div`
  width: 26px;
  height: 26px;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 15px;
    height: 15px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 10px;
    height: 10px;
  `};
`
export const ArrowLeftBorderLeft = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 3;
  display: block;
  width: 2px;
  height: 100%;
`

export const ArrowLeftBorderLeftUp = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 2px;
  height: 50%;
  background-color: #fff;
  animation: ${Horizontal50Keyframes} ${animateSecond};
`

export const ArrowLeftBorderLeftBottom = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 2px;
  height: 50%;
  background-color: #fff;
  animation: ${Horizontal50Keyframes} ${animateSecond};
`

export const ArrowLeftBorderTop = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 3;
  display: block;
  width: 100%;
  height: 2px;
`

export const ArrowLeftBorderHorContent = styled.div`
  width: 100%;
  height: 2px;
  float: right;
  background-color: #fff;
  animation: ${VerticalKeyframes} ${animateSecond};
`

export const ArrowLeftBorderBottom = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 3;
  display: block;
  width: 100%;
  height: 2px;
`
export const ArrowLeftBorderRight = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 3;
  display: block;
  width: 2px;
  height: 100%;
`

export const ArrowLeftBorderRightUp = styled.div`
  position: absolute;
  left: 0px;
  bottom: 50%;
  right: 0px;
  width: 2px;
  height: 50%;
  animation: ${Horizontal50Keyframes} ${animateSecond};
`

export const ArrowLeftBorderRightBottom = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50%;
  width: 2px;
  height: 50%;
  animation: ${Horizontal50Keyframes} ${animateSecond};
`

export const ArrowLeftBorderRightUpContent = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 2px;
  height: 100%;
  background-color: #fff;
  animation: ${Horizontal50Keyframes} ${animateSecond};
`

export const ArrowLeftBorderRightBottomContent = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 2px;
  height: 100%;
  background-color: #fff;
  animation: ${Horizontal50Keyframes} ${animateSecond};
`

export const DecuPanel = styled(animated.div)`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 2;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 0%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background: #222222;
`

export const CarouselPanel = styled(animated.div)`
  z-index: 1;
`
