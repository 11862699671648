import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit'

export const fetchDaoTokenData: Readonly<{
  pending: ActionCreatorWithPayload<{ url: string; requestId: string }>
  fulfilled: ActionCreatorWithPayload<{ url: string; data: any; requestId: string }>
  rejected: ActionCreatorWithPayload<{ url: string; errorMessage: string; requestId: string }>
}> = {
  pending: createAction('datas/fetchDaoTokenData/pending'),
  fulfilled: createAction('datas/fetchDaoTokenData/fulfilled'),
  rejected: createAction('datas/fetchDaoTokenData/rejected'),
}
// add and remove from data options
export const addData = createAction<string>('datas/addData')
export const removeData = createAction<string>('datas/removeData')
export const removeAllDatas = createAction('datas/removeAllDatas')
