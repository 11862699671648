import { BigNumber } from '@ethersproject/bignumber'
import { BRAVO_START_BLOCK } from 'constants/proposals'
import { formatEther } from 'ethers/lib/utils'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useGovernanceContract } from 'hooks/useContract'
import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '../hooks'
import { PropsalInfo } from './hooks'
import {
  addProposalDescription,
  clearAllPorposalData,
  refreshProposals,
  updateProposalCount,
  updateProposalDetail,
  updateProposalReceipt,
  updateProposalState,
} from './reducer'

export default function Updater(): null {
  const dispatch = useAppDispatch()
  const goverance = useAppSelector((state) => state.governance)
  const { account } = useActiveWeb3React()
  const govContract = useGovernanceContract()

  useEffect(() => {
    console.log('goverance updater')
    if (goverance.refresh) {
      return
    }
    dispatch(refreshProposals({ refresh: true }))
    govContract?.proposalCount().then((count: BigNumber) => {
      console.log('count', count.toString())
      if (count && count.gt(0)) {
        // && count.gt(goverance.proposalCount)
        dispatch(updateProposalCount({ count: count.toNumber() }))
        govContract
          ?.queryFilter(govContract?.filters.ProposalCreated(), BRAVO_START_BLOCK)
          .then((events) => {
            console.log('events', events)
            events.forEach((event) => {
              if (event && event?.args) {
                const proposalId = event?.args?.id.toNumber()
                dispatch(addProposalDescription({ proposalId, description: event?.args?.description }))
              }
            })
          })
          .catch((err) => {
            console.log(err)
          })
        for (let i = 1; i <= count.toNumber(); i++) {
          govContract
            ?.proposals(i)
            .then((proposal: any) => {
              // proposals.set(i, proposal)
              console.log('proposal', i, typeof proposal, proposal as PropsalInfo)

              dispatch(
                updateProposalDetail({
                  proposalId: i,
                  detail: {
                    abstainVotes: parseFloat(formatEther(proposal.abstainVotes)).toFixed(3).toLocaleString(),
                    againstVotes: parseFloat(formatEther(proposal.againstVotes)).toFixed(3).toLocaleString(),
                    canceled: proposal.canceled,
                    endBlock: proposal.endBlock.toString(),
                    eta: proposal.eta.toString(),
                    executed: proposal.executed,
                    forVotes: parseFloat(formatEther(proposal.forVotes)).toFixed(3).toLocaleString(),
                    id: proposal.id.toNumber(),
                    proposer: proposal.proposer,
                    startBlock: proposal.startBlock.toString(),
                  },
                })
              )
            })
            .catch((error: any) => {
              console.log(error)
            })

          govContract
            ?.state(i)
            .then((state: number) => {
              // states.set(i, state)
              console.log('state', i, state)
              dispatch(updateProposalState({ proposalId: i, curState: state }))
            })
            .catch((error: any) => console.log(error))
          if (account) {
            govContract
              ?.getReceipt(i, account)
              .then((receipt: any) => {
                // receipts.set(account, receipt)
                console.log('receipt', i, receipt)
                dispatch(
                  updateProposalReceipt({
                    proposalId: i,
                    receipt: {
                      hasVoted: receipt.hasVoted,
                      support: receipt.support,
                      votes: receipt.votes.toString(),
                    },
                  })
                )
              })
              .catch((error: any) => console.log(error))
          }
        }
      } else if (count && !count.eq(goverance.proposalCount)) {
        dispatch(clearAllPorposalData())
      }
    })
  }, [account, dispatch, govContract, goverance])

  return null
}
