import { Contract } from '@ethersproject/contracts'
import DECU_ABI from 'abis/decu.json'
import ENS_PUBLIC_RESOLVER_ABI from 'abis/ens-public-resolver.json'
import GOVERNANCE_ABI from 'abis/governor-bravo.json'
import INCU_ABI from 'abis/incu.json'
import TIMELOCK_ABI from 'abis/timelock.json'
import TREASURY_ABI from 'abis/Treasury.json'
import { EnsPublicResolver } from 'abis/types'
import { Decu } from 'abis/types/Decu'
import { Incu } from 'abis/types/Incu'
import {
  DECU_TOKEN_ADDRESS,
  GOVERNANCE_ADDRESSES,
  INCU_TOKEN_ADDRESS,
  TIMELOCK_ADDRESS,
  TREASURY_ADDRESS,
} from 'constants/addresses'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useMemo } from 'react'

import { getContract } from '../utils'

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { library, account, chainId } = useActiveWeb3React()

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !library || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, ABI, library, chainId, withSignerIfPossible, account]) as T
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean) {
  return useContract<EnsPublicResolver>(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useCultContract(withSignerIfPossible?: boolean) {
  return useContract<Incu>(INCU_TOKEN_ADDRESS, INCU_ABI, withSignerIfPossible)
}

export function useDCultContract(withSignerIfPossible?: boolean) {
  return useContract<Decu>(DECU_TOKEN_ADDRESS, DECU_ABI, withSignerIfPossible)
}

export function useGovernanceContract(withSignerIfPossible?: boolean): Contract | null {
  return useContract(GOVERNANCE_ADDRESSES, GOVERNANCE_ABI, withSignerIfPossible)
}

export function useTimelockContract(withSignerIfPossible?: boolean): Contract | null {
  return useContract(TIMELOCK_ADDRESS, TIMELOCK_ABI, withSignerIfPossible)
}

export function useTreasuryContract(withSignerIfPossible?: boolean): Contract | null {
  return useContract(TREASURY_ADDRESS, TREASURY_ABI, withSignerIfPossible)
}
