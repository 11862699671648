import { Route } from 'react-router-dom'

import Landing from './Landing'

export default function Stake() {
  return (
    <>
      <Route exact strict path="/stake" component={Landing} />
    </>
  )
}
