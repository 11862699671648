import { useChain, useSpring, useSpringRef } from '@react-spring/web'
import { useCallback, useState } from 'react'

import Telegram from '../../assets/images/Icon-awesome-telegram.png'
import BookOpen from '../../assets/images/Icon-feather-book-open.png'
import Home from '../../assets/images/Icon-feather-home.png'
import Twitter from '../../assets/images/Icon-ionic-logo-twitter.png'
import * as S from './styles'

export const Menu = ({
  startAnimate,
  onMenuAnimationEnd,
}: {
  startAnimate: boolean
  onMenuAnimationEnd: () => void
}) => {
  const [horMenu, setHorMenu] = useState(false)
  const [bottomMenu, setBottomMenu] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const hamWrapperApi = useSpringRef()
  const { hamWrapperZ } = useSpring({
    ref: hamWrapperApi,
    hamWrapperZ: showMenu ? '45deg' : '0deg',
    config: { duration: 200 },
  })
  const hamVerLineApi = useSpringRef()
  const { hamVerLineX } = useSpring({
    ref: hamVerLineApi,
    hamVerLineX: showMenu ? '-25px' : '0px',
    config: { duration: 200 },
  })
  const hamVerLine2Api = useSpringRef()
  const { hamVer2LineX } = useSpring({
    ref: hamVerLine2Api,
    hamVer2LineX: showMenu ? '25px' : '0px',
    config: { duration: 200 },
  })

  const hamLineMidApi = useSpringRef()
  const { hamLineMidZ } = useSpring({
    ref: hamLineMidApi,
    hamLineMidZ: showMenu ? '90deg' : '0deg',
    config: { duration: 200 },
  })
  useChain(
    showMenu
      ? [hamWrapperApi, hamVerLineApi, hamVerLine2Api, hamLineMidApi]
      : [hamLineMidApi, hamVerLine2Api, hamVerLineApi, hamWrapperApi],
    showMenu ? [0, 0.2, 0.4, 0.4] : [0, 0, 0.2, 0.4]
  )
  const handleMenuClick = useCallback(() => {
    setShowMenu(!showMenu)
  }, [showMenu])

  const FirstRightLineApi = useSpringRef()
  const { FirstRightWidth } = useSpring({
    ref: FirstRightLineApi,
    FirstRightWidth: showMenu ? '100%' : '0%',
    config: { duration: 200 },
  })

  const FirstBorderLeftApi = useSpringRef()
  const { FirstRightLeftHeight } = useSpring({
    ref: FirstBorderLeftApi,
    FirstRightLeftHeight: showMenu ? '100%' : '0%',
    config: { duration: 200 },
  })

  const FirstBorderVerApi = useSpringRef()
  const { FirstRightVerWidth } = useSpring({
    ref: FirstBorderVerApi,
    FirstRightVerWidth: showMenu ? '100%' : '0%',
    config: { duration: 200 },
  })

  const FirstIconApi = useSpringRef()
  const { FirstRightIconOpacity } = useSpring({
    ref: FirstIconApi,
    FirstRightIconOpacity: showMenu ? 1 : 0,
    config: { duration: 100 },
  })

  const FirstBorderRightApi = useSpringRef()
  const { FirstRightRightHeight } = useSpring({
    ref: FirstBorderRightApi,
    FirstRightRightHeight: showMenu ? '100%' : '0%',
    config: { duration: 200 },
  })
  const FirstPanelApi = useSpringRef()
  const { FirstPanelDisplay } = useSpring({
    ref: FirstPanelApi,
    FirstPanelDisplay: showMenu ? 'block' : 'none',
    config: { duration: 200 },
  })

  const SecondRightLineApi = useSpringRef()
  const { SecondRightWidth } = useSpring({
    ref: SecondRightLineApi,
    SecondRightWidth: showMenu ? '100%' : '0%',
    config: { duration: 200 },
  })

  const SecondBorderLeftApi = useSpringRef()
  const { SecondRightLeftHeight } = useSpring({
    ref: SecondBorderLeftApi,
    SecondRightLeftHeight: showMenu ? '100%' : '0%',
    config: { duration: 200 },
  })

  const SecondBorderVerApi = useSpringRef()
  const { SecondRightVerWidth } = useSpring({
    ref: SecondBorderVerApi,
    SecondRightVerWidth: showMenu ? '100%' : '0%',
    config: { duration: 200 },
  })

  const SecondIconApi = useSpringRef()
  const { SecondRightIconOpacity } = useSpring({
    ref: SecondIconApi,
    SecondRightIconOpacity: showMenu ? 1 : 0,
    config: { duration: 100 },
  })

  const SecondBorderRightApi = useSpringRef()
  const { SecondRightRightHeight } = useSpring({
    ref: SecondBorderRightApi,
    SecondRightRightHeight: showMenu ? '100%' : '0%',
    config: { duration: 200 },
  })
  const SecondPanelApi = useSpringRef()
  const { SecondPanelDisplay } = useSpring({
    ref: SecondPanelApi,
    SecondPanelDisplay: showMenu ? 'block' : 'none',
    config: { duration: 200 },
  })

  useChain(
    showMenu
      ? [
          FirstRightLineApi,
          FirstPanelApi,
          FirstBorderLeftApi,
          FirstBorderVerApi,
          FirstIconApi,
          FirstBorderRightApi,
          SecondRightLineApi,
          SecondPanelApi,
          SecondBorderLeftApi,
          SecondBorderVerApi,
          SecondIconApi,
          SecondBorderRightApi,
        ]
      : [
          SecondBorderRightApi,
          SecondIconApi,
          SecondBorderVerApi,
          SecondBorderLeftApi,
          SecondPanelApi,
          SecondRightLineApi,
          FirstBorderRightApi,
          FirstIconApi,
          FirstBorderVerApi,
          FirstBorderLeftApi,
          FirstPanelApi,
          FirstRightLineApi,
        ],
    showMenu
      ? [0, 0.2, 0.2, 0.4, 0.5, 0.6, 0.8, 0.8, 0.9, 1, 1.2]
      : [0, 0.1, 0.2, 0.4, 0.6, 0.6, 0.8, 0.9, 1.0, 1.2, 1.2]
  )

  const FirstLeftLineApi = useSpringRef()
  const { FirstLeftWidth } = useSpring({
    ref: FirstLeftLineApi,
    FirstLeftWidth: showMenu ? '100%' : '0%',
    config: { duration: 200 },
  })

  const FirstLeftBorderLeftApi = useSpringRef()
  const { FirstLeftLeftHeight } = useSpring({
    ref: FirstLeftBorderLeftApi,
    FirstLeftLeftHeight: showMenu ? '100%' : '0%',
    config: { duration: 200 },
  })

  const FirstLeftBorderVerApi = useSpringRef()
  const { FirstLeftVerWidth } = useSpring({
    ref: FirstLeftBorderVerApi,
    FirstLeftVerWidth: showMenu ? '100%' : '0%',
    config: { duration: 200 },
  })

  const FirstLeftIconApi = useSpringRef()
  const { FirstLeftIconOpacity } = useSpring({
    ref: FirstLeftIconApi,
    FirstLeftIconOpacity: showMenu ? 1 : 0,
    config: { duration: 100 },
  })

  const FirstLeftBorderRightApi = useSpringRef()
  const { FirstLeftRightHeight } = useSpring({
    ref: FirstLeftBorderRightApi,
    FirstLeftRightHeight: showMenu ? '100%' : '0%',
    config: { duration: 200 },
  })
  const FirstLeftPanelApi = useSpringRef()
  const { FirstLeftPanelDisplay } = useSpring({
    ref: FirstLeftPanelApi,
    FirstLeftPanelDisplay: showMenu ? 'block' : 'none',
    config: { duration: 200 },
  })

  const SecondLeftLineApi = useSpringRef()
  const { SecondLeftWidth } = useSpring({
    ref: SecondLeftLineApi,
    SecondLeftWidth: showMenu ? '100%' : '0%',
    config: { duration: 200 },
  })

  const SecondLeftBorderLeftApi = useSpringRef()
  const { SecondLeftLeftHeight } = useSpring({
    ref: SecondLeftBorderLeftApi,
    SecondLeftLeftHeight: showMenu ? '100%' : '0%',
    config: { duration: 200 },
  })

  const SecondLeftBorderVerApi = useSpringRef()
  const { SecondLeftVerWidth } = useSpring({
    ref: SecondLeftBorderVerApi,
    SecondLeftVerWidth: showMenu ? '100%' : '0%',
    config: { duration: 200 },
  })

  const SecondLeftIconApi = useSpringRef()
  const { SecondLeftIconOpacity } = useSpring({
    ref: SecondLeftIconApi,
    SecondLeftIconOpacity: showMenu ? 1 : 0,
    config: { duration: 100 },
  })

  const SecondLeftBorderRightApi = useSpringRef()
  const { SecondLeftRightHeight } = useSpring({
    ref: SecondLeftBorderRightApi,
    SecondLeftRightHeight: showMenu ? '100%' : '0%',
    config: { duration: 200 },
  })
  const SecondLeftPanelApi = useSpringRef()
  const { SecondLeftPanelDisplay } = useSpring({
    ref: SecondLeftPanelApi,
    SecondLeftPanelDisplay: showMenu ? 'block' : 'none',
    config: { duration: 200 },
  })

  useChain(
    showMenu
      ? [
          FirstLeftLineApi,
          FirstLeftPanelApi,
          FirstLeftBorderRightApi,
          FirstLeftBorderVerApi,
          FirstLeftIconApi,
          FirstLeftBorderLeftApi,
          SecondLeftLineApi,
          SecondLeftPanelApi,
          SecondLeftBorderRightApi,
          SecondLeftBorderVerApi,
          SecondLeftIconApi,
          SecondLeftBorderLeftApi,
        ]
      : [
          SecondLeftBorderLeftApi,
          SecondLeftIconApi,
          SecondLeftBorderVerApi,
          SecondLeftBorderRightApi,
          SecondLeftPanelApi,
          SecondLeftLineApi,
          FirstLeftBorderLeftApi,
          FirstLeftIconApi,
          FirstLeftBorderVerApi,
          FirstLeftBorderRightApi,
          FirstLeftPanelApi,
          FirstLeftLineApi,
        ],
    showMenu
      ? [0, 0.2, 0.2, 0.4, 0.5, 0.6, 0.8, 0.8, 0.9, 1, 1.2]
      : [0, 0.1, 0.2, 0.4, 0.6, 0.6, 0.8, 0.9, 1.0, 1.2, 1.2]
  )
  return (
    <>
      <S.MenuDiv onClick={handleMenuClick}>
        {horMenu && (
          <S.HamWrapper
            style={{
              rotateZ: hamWrapperZ,
            }}
          >
            <S.HamVerLine style={{ translateX: hamVerLineX }}></S.HamVerLine>
            <S.HamLineMid>
              <S.HamLineMidContent1></S.HamLineMidContent1>
              <S.HamLineMidContent style={{ rotateZ: hamLineMidZ }}></S.HamLineMidContent>
            </S.HamLineMid>
            <S.HamVerLine2 style={{ translateX: hamVer2LineX }}></S.HamVerLine2>
          </S.HamWrapper>
        )}
        <S.MenuUpBorder>
          {startAnimate && (
            <S.MenuUpContent
              onAnimationEnd={() => {
                setHorMenu(true)
              }}
            ></S.MenuUpContent>
          )}
        </S.MenuUpBorder>
        <S.MenuLeftBorder>
          {horMenu && (
            <S.MenuLeftContent
              onAnimationEnd={() => {
                setBottomMenu(true)
              }}
            ></S.MenuLeftContent>
          )}
        </S.MenuLeftBorder>
        <S.MenuRightBorder>{horMenu && <S.MenuRightContent></S.MenuRightContent>}</S.MenuRightBorder>
        <S.MenuBottomBorder>
          {bottomMenu && (
            <S.MenuBottomContent1
              onAnimationEnd={() => {
                onMenuAnimationEnd()
              }}
            ></S.MenuBottomContent1>
          )}
          {bottomMenu && <S.MenuBottomContent2></S.MenuBottomContent2>}
        </S.MenuBottomBorder>
      </S.MenuDiv>
      <S.MenuRightFirstLine>
        <S.MenuRightFirstLineContent style={{ width: FirstRightWidth }}></S.MenuRightFirstLineContent>
      </S.MenuRightFirstLine>
      <S.MenuRightFirstPanel style={{ display: FirstPanelDisplay }}>
        <S.MenuLink href={'https://t.me/incudao'}>
          <S.MenuBorderLeft>
            <S.MenuBorderLeftUp>
              <S.MenuBorderLeftUpContent style={{ height: FirstRightLeftHeight }}></S.MenuBorderLeftUpContent>
            </S.MenuBorderLeftUp>
            <S.MenuBorderLeftBottom>
              <S.MenuBorderLeftBottomContent style={{ height: FirstRightLeftHeight }}></S.MenuBorderLeftBottomContent>
            </S.MenuBorderLeftBottom>
          </S.MenuBorderLeft>
          <S.MenuBorderTop>
            <S.MenuRightBorderVer style={{ width: FirstRightVerWidth }}></S.MenuRightBorderVer>
          </S.MenuBorderTop>
          <S.MenuBorderBottom>
            <S.MenuRightBorderVer style={{ width: FirstRightVerWidth }}></S.MenuRightBorderVer>
          </S.MenuBorderBottom>
          <S.MenuBorderRight>
            <S.MenuBorderRightUp style={{ height: FirstRightRightHeight }}></S.MenuBorderRightUp>
            <S.MenuBorderRightBottom style={{ height: FirstRightRightHeight }}></S.MenuBorderRightBottom>
          </S.MenuBorderRight>
          <S.MenuIcon style={{ opacity: FirstRightIconOpacity }}>
            <S.MenuIconWrapper>
              <S.MenuImg src={Telegram} alt=""></S.MenuImg>
            </S.MenuIconWrapper>
          </S.MenuIcon>
        </S.MenuLink>
      </S.MenuRightFirstPanel>
      <S.MenuRightSecondLine>
        <S.MenuRightSecondLineContent style={{ width: SecondRightWidth }}></S.MenuRightSecondLineContent>
      </S.MenuRightSecondLine>
      <S.MenuRightSecondPanel style={{ display: SecondPanelDisplay }}>
        <S.MenuLink href={'http://incu.club/doc'}>
          <S.MenuBorderLeft>
            <S.MenuBorderLeftUp>
              <S.MenuBorderLeftUpContent style={{ height: SecondRightLeftHeight }}></S.MenuBorderLeftUpContent>
            </S.MenuBorderLeftUp>
            <S.MenuBorderLeftBottom>
              <S.MenuBorderLeftBottomContent style={{ height: SecondRightLeftHeight }}></S.MenuBorderLeftBottomContent>
            </S.MenuBorderLeftBottom>
          </S.MenuBorderLeft>
          <S.MenuBorderTop>
            <S.MenuRightBorderVer style={{ width: SecondRightVerWidth }}></S.MenuRightBorderVer>
          </S.MenuBorderTop>
          <S.MenuBorderBottom>
            <S.MenuRightBorderVer style={{ width: SecondRightVerWidth }}></S.MenuRightBorderVer>
          </S.MenuBorderBottom>
          <S.MenuBorderRight>
            <S.MenuBorderRightUp style={{ height: SecondRightRightHeight }}></S.MenuBorderRightUp>
            <S.MenuBorderRightBottom style={{ height: SecondRightRightHeight }}></S.MenuBorderRightBottom>
          </S.MenuBorderRight>
          <S.MenuIcon style={{ opacity: SecondRightIconOpacity }}>
            <S.MenuIconWrapper>
              <S.MenuImg src={BookOpen} alt=""></S.MenuImg>
            </S.MenuIconWrapper>
          </S.MenuIcon>
        </S.MenuLink>
      </S.MenuRightSecondPanel>

      <S.MenuLeftFirstLine>
        <S.MenuLeftFirstLineContent style={{ width: FirstLeftWidth }}></S.MenuLeftFirstLineContent>
      </S.MenuLeftFirstLine>
      <S.MenuLeftFirstPanel style={{ display: FirstLeftPanelDisplay }}>
        <S.MenuLink href={'https://twitter.com/IncuDao'}>
          <S.MenuBorderLeft>
            <S.MenuLeftBorderLeftUp style={{ height: FirstLeftLeftHeight }}></S.MenuLeftBorderLeftUp>
            <S.MenuLeftBorderLeftBottom style={{ height: FirstLeftLeftHeight }}></S.MenuLeftBorderLeftBottom>
          </S.MenuBorderLeft>
          <S.MenuBorderTop>
            <S.MenuLeftBorderVer style={{ width: FirstLeftVerWidth }}></S.MenuLeftBorderVer>
          </S.MenuBorderTop>
          <S.MenuBorderBottom>
            <S.MenuLeftBorderVer style={{ width: FirstLeftVerWidth }}></S.MenuLeftBorderVer>
          </S.MenuBorderBottom>
          <S.MenuBorderRight>
            <S.MenuLeftBorderRightUp>
              <S.MenuLeftBorderRightUpContent style={{ height: FirstLeftRightHeight }}></S.MenuLeftBorderRightUpContent>
            </S.MenuLeftBorderRightUp>
            <S.MenuLeftBorderRightBottom>
              <S.MenuLeftBorderRightBottomContent
                style={{ height: FirstLeftRightHeight }}
              ></S.MenuLeftBorderRightBottomContent>
            </S.MenuLeftBorderRightBottom>
          </S.MenuBorderRight>
          <S.MenuIcon style={{ opacity: FirstLeftIconOpacity }}>
            <S.MenuIconWrapper>
              <S.MenuImg src={Twitter} alt=""></S.MenuImg>
            </S.MenuIconWrapper>
          </S.MenuIcon>
        </S.MenuLink>
      </S.MenuLeftFirstPanel>

      <S.MenuLeftSecondLine>
        <S.MenuLeftSecondLineContent style={{ width: SecondLeftWidth }}></S.MenuLeftSecondLineContent>
      </S.MenuLeftSecondLine>
      <S.MenuLeftSecondPanel style={{ display: SecondLeftPanelDisplay }} onClick={handleMenuClick}>
        <S.MenuHomeLink>
          <S.MenuBorderLeft>
            <S.MenuLeftBorderLeftUp style={{ height: SecondLeftLeftHeight }}></S.MenuLeftBorderLeftUp>
            <S.MenuLeftBorderLeftBottom style={{ height: SecondLeftLeftHeight }}></S.MenuLeftBorderLeftBottom>
          </S.MenuBorderLeft>
          <S.MenuBorderTop>
            <S.MenuLeftBorderVer style={{ width: SecondLeftVerWidth }}></S.MenuLeftBorderVer>
          </S.MenuBorderTop>
          <S.MenuBorderBottom>
            <S.MenuLeftBorderVer style={{ width: SecondLeftVerWidth }}></S.MenuLeftBorderVer>
          </S.MenuBorderBottom>
          <S.MenuBorderRight>
            <S.MenuLeftBorderRightUp>
              <S.MenuLeftBorderRightUpContent
                style={{ height: SecondLeftRightHeight }}
              ></S.MenuLeftBorderRightUpContent>
            </S.MenuLeftBorderRightUp>
            <S.MenuLeftBorderRightBottom>
              <S.MenuLeftBorderRightBottomContent
                style={{ height: SecondLeftRightHeight }}
              ></S.MenuLeftBorderRightBottomContent>
            </S.MenuLeftBorderRightBottom>
          </S.MenuBorderRight>
          <S.MenuHomeIcon style={{ opacity: SecondLeftIconOpacity }}>
            <S.MenuIconWrapper>
              <S.MenuImg src={Home} alt=""></S.MenuImg>
            </S.MenuIconWrapper>
          </S.MenuHomeIcon>
        </S.MenuHomeLink>
      </S.MenuLeftSecondPanel>
    </>
  )
}
