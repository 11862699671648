import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useFetchDataCallback } from 'hooks/useFetchListCallback'
import { useEffect } from 'react'
import { useAllDatas } from 'state/datas/hooks'

export default function Updater(): null {
  const { library } = useActiveWeb3React()

  console.log('datas Updater')
  // get all loaded lists, and the active urls
  const datas = useAllDatas()
  const fetchList = useFetchDataCallback()
  console.log('datas', datas)

  // whenever a list is not loaded and not loading, try again to load it
  useEffect(() => {
    Object.keys(datas).forEach((dataUrl) => {
      const list = datas[dataUrl]
      if (!list.current && !list.loadingRequestId && !list.error) {
        fetchList(dataUrl).catch((error) => console.debug('list added fetching error', error))
      }
    })
  }, [fetchList, library, datas])

  return null
}
