import { nanoid } from '@reduxjs/toolkit'
import getTokenData from 'lib/hooks/useFetchDaoTokenData'
import { useCallback } from 'react'
import { useAppDispatch } from 'state/hooks'

import { fetchDaoTokenData } from '../state/datas/actions'

export function useFetchDataCallback(): (dataUrl: string, sendDispatch?: boolean) => Promise<any> {
  const dispatch = useAppDispatch()
  // note: prevent dispatch if using for list search or unsupported list
  return useCallback(
    async (dataUrl: string, sendDispatch = true) => {
      const requestId = nanoid()
      sendDispatch && dispatch(fetchDaoTokenData.pending({ requestId, url: dataUrl }))
      return getTokenData(dataUrl)
        .then((tokenData) => {
          sendDispatch && dispatch(fetchDaoTokenData.fulfilled({ url: dataUrl, data: tokenData, requestId }))
          return tokenData
        })
        .catch((error) => {
          console.debug(`Failed to get list at url ${dataUrl}`, error)
          sendDispatch && dispatch(fetchDaoTokenData.rejected({ url: dataUrl, requestId, errorMessage: error.message }))
          throw error
        })
    },
    [dispatch]
  )
}
